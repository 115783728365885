import { NgModule } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { DataTableComponent } from './components/data-table/data-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BaseModalComponent } from './components/modal/modal.component';
import { AgGridAngular } from 'ag-grid-angular';

import { LinkCellRendererComponent } from './components/data-table/components/link-cell-renderer.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FiltersComponent } from './components/data-table/components/filters/filters.component';
import { NgbAccordionModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ColumnsComponent } from './components/data-table/components/columns/columns.component';
import { RouterModule } from '@angular/router';
import { ButtonCellRendererComponent } from './components/data-table/components/button-cell-renderer/button-cell-renderer.component';
import { ModalDetailTransactionComponent } from './components/data-table/components/modal-detail-transaction/modal-transaction.component';
import { ModalDetailMerchantsComponent } from './components/data-table/components/modal-detail-merchants/modal-detail-merchants.component';
import { ModalAnnulmentComponent } from './components/data-table/components/modal-annulment/modal-annulment.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdwonCellRendererComponent } from './components/data-table/components/dropdwon-cell-renderer/dropdwon-cell-renderer.component';
import { ModalDetailPayLinkComponent } from './components/data-table/components/modal-detail-pay-link/modal-detail-pay-link.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ModaDetailPaylinkComponent } from './components/data-table/components/moda-detail-paylink/moda-detail-paylink.component';
import { ModalDetailRechargeComponent } from './components/data-table/components/modal-detail-recharge/modal-detail-recharge.component';
import { ModalDetailSalesComponent } from './components/data-table/components/modal-detail-sales/modal-detail-sales.component';
import { BtnGroupComponent } from './components/data-table/components/btn-group/btn-group.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { ModalReversedBalanceComponent } from './components/data-table/components/modal-reversed-balance/modal-reversed-balance.component';
import { ModalLogSalesComponent } from './components/data-table/components/modal-log-sales/modal-log-sales.component';
import { ModalThirdPartyLogsComponent } from './components/data-table/components/modal-third-party-logs/modal-third-party-logs.component';
import { DynamicFormFieldComponent } from './components/dynamic-form/dynamic-form-field/dynamic-form-field.component';
import { LazyLoadingSelectComponent } from './components/lazy-loading-select/lazy-loading-select.component';
import { NgSelectModule } from '@ng-select/ng-select';



const sharedComponents = [
  DataTableComponent,
  BaseModalComponent,
  FiltersComponent,
  ColumnsComponent,
  ButtonCellRendererComponent,
  ModalDetailTransactionComponent,
  ModalDetailMerchantsComponent,
  ModalAnnulmentComponent,
  ModalDetailPayLinkComponent,
  DropdwonCellRendererComponent,
  ModaDetailPaylinkComponent,
  ModalDetailRechargeComponent,
  ModalDetailSalesComponent,
  BtnGroupComponent,
  ModalReversedBalanceComponent,
  ModalLogSalesComponent,
  ModalThirdPartyLogsComponent,
  DynamicFormComponent,
  DynamicFormFieldComponent,
  LazyLoadingSelectComponent,
  
];
const agGridComponents = [
  LinkCellRendererComponent
];

@NgModule({
  declarations: [
    ...sharedComponents,
   ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AgGridAngular, 
    NgbAccordionModule,
    AgGridAngular,
    RouterModule,
    NgbPopoverModule,
    NgbTypeaheadModule,
    JsonPipe,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    ShareButtonsModule.withConfig({
      theme: 'modern-dark', // Asegúrate de que el tema esté configurado aquí si es necesario
    }),

  ],
  exports: [
    ...sharedComponents

  ]
})

export class SharedModule { }
