import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RechargeDetails } from '@shared/models/recharge.models';
import { TenantService } from '@shared/services/tenant.service';
import { AlertsService } from '@shared/services/alerts.service';

@Injectable({
    providedIn: 'root',
})
export class RechargeServices {

    private http = inject(HttpClient);
    private tenantService = inject(TenantService);
    private alerts = inject(AlertsService);

    detailsRecharge(transactionId: string): Observable<RechargeDetails | undefined> {
        const url = `${this.tenantService.api}/cmstransaction/transactionId/${transactionId}`;
        return this.http.get<RechargeDetails>(url).pipe(
            catchError(error => {
                this.alerts.error(`Error: ${error.status}`, 'Error al obtener los detalles de la recarga');
                return throwError(() => error);
            })
        );
    }

    getReversed(externalId: string): Observable<RechargeDetails | undefined> {
        const url = `${this.tenantService.api}/cmstransaction/reverse/${externalId}`;
        return this.http.delete<RechargeDetails>(url).pipe(
            catchError(error => {
                this.alerts.error(`Error: ${error.status}`, 'Error al reembolsar la recarga');
                return throwError(() => error);
            })
        );
    }
}