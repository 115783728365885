import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralStatus, STATUS_CLASS_MAP, STATUS_TRANSLATIONS } from '@shared/models/enum-status.models';
import { AlertsService } from '@shared/services/alerts.service';
import { CurrencyService } from '@shared/services/currency.service';
import { Subscription } from 'src/app/tenant/subscription/models/form.models';
import { SubscriptionService } from 'src/app/tenant/subscription/services/subscription.service';

@Component({
  selector: 'app-modal-desactive',
  templateUrl: './modal-desactive.component.html',
  styleUrl: './modal-desactive.component.scss'
})
export class ModalDesactiveComponent implements OnInit {
  @Output() subscriptionDesactive = new EventEmitter<void>();
  @Input() status!: string;
  @Input() subscriptionId!: string;
  listSus!: Subscription;
  subcripDesactive!:Subscription;
  isDisabled: boolean = false;

  private subscriptionService = inject(SubscriptionService);
  private currencyService = inject(CurrencyService);
   private alertsService = inject(AlertsService);
  private currencyPipe = inject(CurrencyPipe);
  public activeModal = inject(NgbActiveModal);

ngOnInit(): void {
  this.getDetailsSus();
}

getDetailsSus(): void {
  this.subscriptionService.idSuscription(this.subscriptionId).subscribe(response => {
    this.listSus = response;
  }, error => {
    console.error('Error fetching subscription details:', error);
  });
}

  public getStatusClass(status: GeneralStatus): string {
    return STATUS_CLASS_MAP[status] || "badge-soft-primary";
  }

  public getStatusInSpanish(status: GeneralStatus): string {
    return STATUS_TRANSLATIONS[status] || "Desconocido";
  }

  getCurrencySymbol(currencyCode: string): string {
    return this.currencyService.getCurrencySymbol(currencyCode);
  }

  formatAmount(value: number, currency: string): string {
    if (currency === 'PYG') {
      return parseFloat(value.toString()).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return this.currencyPipe.transform(value?.toString(), '', '', '1.2-2');
  }


   checkIfAnulled(subcrip: Subscription): boolean {
      // Aquí asumimos que ya fue anulada
      return subcrip?.status === 'SUSPENDED';
    }

  onAccept(): void {
    if (this.checkIfAnulled(this.subcripDesactive)) {
      this.alertsService.error('Esta transacción ya ha sido cancelada');
    } else {
      this.subscriptionService.desacSubcription(this.subscriptionId, this.status)
        .subscribe({
          next: () => {
            console.log('prueba');
            
            this.isDisabled = true;
            this.alertsService.success('Subscripción desactivada con éxito.');
            this.subscriptionDesactive.emit(); // event
            this.activeModal.close();
          },
          error: (err) => {
            console.error('Error canceling transaction:', err);
            this.alertsService.error('Error al intentar desactivar.');
          }
        });
    }
  }
}
