
<multitenant-base-modal>
    <div class="modal-title d-flex align-items-center mb-2 justify-content-center">
      <i class="bx bx-body text-success small-icon"></i>
      <h4 class="ms-2 mb-0 custom-button">{{ getChargeDetailsTitle() }}</h4>
    </div>
    <div class="body-container">
      <div class="tab-content" id="nav-tabContent" style="position: relative;top: 10px;">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
          <div class="card colors-cards">
            <p class="tab-text-commerm">{{ getChargeDetailsText() }}</p>
            <div class="card-body">
              <div class="btn-container">
                <label class="text-commerm">Código de Cargo:</label>
                <label class="text-rest">{{detailCharge?.uuid}}</label>
              </div>
              
              <!-- <div class="btn-container">
                <label class="text-commerm">Tipo de Cargo:</label>
                <label class="text-ruc">{{ getChargeTypeInSpanish(detailCharge?.type) }}</label>

              </div> -->
              <div class="btn-container">
                <label class="text-commerm">Fecha de Ejecución:</label>
                <label class="text-ruc">{{detailCharge?.executionDate | date: 'dd/MM/yyyy HH:mm:ss'}}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Fecha Final:</label>
                <label class="text-ruc">{{detailCharge?.finalDate | date: 'dd/MM/yyyy HH:mm:ss'}}</label>
              </div>
            
              <div class="btn-container">
                <label class="text-commerm">Estado:</label>
                 <span [ngClass]="[getStatusClass(detailCharge?.status),'no-background']">
                  <label class="text-center-status">{{getStatusInSpanish(detailCharge?.status)}}</label> </span>
              </div>
  
            </div>
          </div>
        </div> 
      </div> 
    </div> 
  
    <div class="modal-footer justify-content-center">
      <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">Cerrar</button>

      <div class="btn-container">
        <button type="button" class="btn btn-outline-warning"
        (click)="onDownload()">
        <i class="bx bx-download"></i>
        Descargar
      </button>
      </div>
    </div>

   
  </multitenant-base-modal>
