import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { AuthService } from "@shared/services/auth.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canAccess(route.paramMap.get('tenant'), state.url);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    const urlTree = this.router.getCurrentNavigation().extractedUrl;
    return this.canAccess(segments[0]?.path, this.router.serializeUrl(urlTree));
  }

  private canAccess(tenant: string | undefined, next: string): | Observable<boolean | UrlTree> {
    return this.authService.isAuthenticated$.pipe(
      map((isAuth) => {
        if (!isAuth) {
          const level = sessionStorage.getItem('level');
          const loginUrl = level ? `${tenant}/login?level=${level}` : `${tenant}/login`;
          return this.router.parseUrl(loginUrl);
        }
        return true;
      })
    );
  }
}
