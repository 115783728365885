import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { TenantService } from './tenant.service';
import { environment } from '@environments/environment';
import { HttpClient, HttpContext } from '@angular/common/http';
import { BYPASS_LOG } from '@shared/interceptors/http-token.interceptor';


@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  server = '';
  client = '';

  get serverApi(): string {
    return this.server;
  }

  get catalogApi(): string {
    return environment.catalogServer ;
  }

  constructor(
    private router: Router,
    private tenantService: TenantService,
    private httpClient: HttpClient,
  ) { }

  setServer(client: string): Observable<boolean> {
    client = client.toLowerCase();

    return this.getServerInfo(client).pipe(
      take(1),
      map(serverInfo => {
        this.server = serverInfo.url;
        const serverFound = !!this.server;
        if (serverFound) {
          this.tenantService.tenantName = client;
          this.tenantService.setStyleTheme(this.serverApi);
          this.tenantService.setCompanyInformation(client, this.serverApi);
          this.client = client;
        } else {
          this.client = '';
        }
        return serverFound;
      }),
      catchError(error => {
        this.server = '';
        this.client = '';
        this.router.navigate(['/', 'not-found']);
        return throwError(() => error);
      })
    );
  }

  validClient(client: string): boolean {
    return this.client === client.toLowerCase();
  }

  private getServerInfo(client: string): Observable<{url: string}> {
    const options = {context: new HttpContext().set(BYPASS_LOG, true)};
    return this.httpClient.get<{url: string}>(`${this.catalogApi}/${client}/url`, options)
      .pipe(map(response => response));
  }
}
