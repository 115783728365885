import { Component, inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-third-party-logs',
  templateUrl: './modal-third-party-logs.component.html',
  styleUrls: ['./modal-third-party-logs.component.scss']
})
export class ModalThirdPartyLogsComponent implements OnInit {
  @Input() logDetails!: { name?: string; request?: string; response?: string; createdOn?: string; status?: number };


  public activeModal = inject(NgbActiveModal);

  ngOnInit(): void {
    
  }
}
