import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnullmentResponse } from '@shared/models/anullmente.models';
import { STATUS_CLASS_MAP_TRANSACTION, StatusTransaction } from '@shared/models/enum.models';
import { DetailsTransaction } from '@shared/models/modal-transaction.models';
import { AlertsService } from '@shared/services/alerts.service';
import { AnullmentService } from '@shared/services/anullment.service';
import { CurrencyService } from '@shared/services/currency.service';
import { TransactionServices } from 'src/app/tenant/transactions/services/transaction.service';

@Component({
  selector: 'app-modal-annulment',
  templateUrl: './modal-annulment.component.html',
  styleUrl: './modal-annulment.component.scss'
})
export class ModalAnnulmentComponent implements OnInit {
  @Output() transactionAnnulled = new EventEmitter<void>();
  @Input() merchantId!: string;
  @Input() saleId!: string;
  transactionDetails!: DetailsTransaction;
  merchantAnullment!: DetailsTransaction;
  isAnulled: boolean = false;

  private transactionServices = inject(TransactionServices);
  public activeModal = inject(NgbActiveModal);
  private anullmentService = inject(AnullmentService);
  private alertsService = inject(AlertsService);
  private currencyPipe = inject(CurrencyPipe);
  private currencyService = inject(CurrencyService);

  ngOnInit(): void {
    this.getTransactionDetails();
  }

  getTransactionDetails() {
    this.transactionServices.detailsTrasactionApi(this.merchantId, this.saleId)
      .subscribe(details => {
        this.transactionDetails = details;
      });
  }

  public getStatusClass(status: StatusTransaction): string {
    return STATUS_CLASS_MAP_TRANSACTION[status] || "badge-soft-primary";
  }

  public getStatusInSpanish(status: StatusTransaction): string {
    const statusMap: { [key in StatusTransaction]: string } = {
      [StatusTransaction.AUTHORIZED]: "AUTORIZADO",
      [StatusTransaction.UNEXPECTED]: "INESPERADO",
      [StatusTransaction.DENIED]: "DENEGADO",
      [StatusTransaction.VOIDED]: "ANULADO",
    };
    return statusMap[status];
  }

  getCurrencySymbol(currencyCode: string): string {
    return this.currencyService.getCurrencySymbol(currencyCode);
  }

  formatAmount(value: number, currency: string): string {
    if (currency === 'PYG') {
      return parseFloat(value.toString()).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return this.currencyPipe.transform(value?.toString(), '', '', '1.2-2');
  }

  checkIfAnulled(merchant: DetailsTransaction): boolean {
    // Aquí asumimos que ya fue anulada
    return merchant?.status === 'VOIDED';
  }

  onAccept(): void {

    if (this.checkIfAnulled(this.merchantAnullment)) {
      this.alertsService.error('Esta transacción ya ha sido cancelada');
    } else {
      this.anullmentService.deleteAnullmente(this.merchantId, this.saleId)
        .subscribe({
          next: () => {
            this.isAnulled = true;
            this.alertsService.success('Transacción anulada con éxito.');
            this.transactionAnnulled.emit(); // event
            this.activeModal.close();
          },
          error: (err) => {
            console.error('Error canceling transaction:', err);
            this.alertsService.error('There was a problem canceling the transaction.');
          }
        });
    }
  }



}
