import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";

import { UserService } from "@shared/services/user.service";
import { Observable, throwError } from "rxjs";
import { CreatePayLinkResponse } from "../models/payment.models";
import { PaylinkDetails } from "@shared/models/paylink-details-models";
import { catchError } from 'rxjs/operators';
import { AlertsService } from "@shared/services/alerts.service";
import { TenantService } from "@shared/services/tenant.service";


@Injectable({
  providedIn: "root",
})
export class Paymentservices {

  private http = inject(HttpClient);
  private tenantService = inject(TenantService);
  private userService = inject(UserService);
  private alerts = inject(AlertsService);

  newLink(data: any): Observable<CreatePayLinkResponse> {
    const user = this.userService.currentUser;
    let endpoint = `${this.tenantService.api}/paylink`;
    if (user.isMerchantAdmin) {
      endpoint = `${endpoint}/${user.chosenMerchant.merchantId}`;
    }
    return this.http.post<CreatePayLinkResponse>(endpoint, data).pipe(
      catchError((error) => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener el link');
        return throwError(() => new Error(error));
      })
    );
  }

  editLink(Id: string, data: any): Observable<CreatePayLinkResponse> {
    const user = this.userService.currentUser;
    let endpoint = `${this.tenantService.api}/paylink`;
    if (user.isMerchantAdmin) {
      endpoint = `${endpoint}/${user.chosenMerchant.merchantId}`;
    }
    endpoint = `${endpoint}/${Id}`;
    return this.http.put<CreatePayLinkResponse>(endpoint, data).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener el id');
        return throwError(() => new Error(error));
      })
    );
  }

  getLink(Id: string): Observable<any> {
    return this.http.get<any>(`/api/paylink/${Id}`);
  }

  getPaylinkDetails(merchantId: string, orderId: string): Observable<PaylinkDetails | undefined> {
    const url = `${this.tenantService.api}/paylink/${merchantId}/${orderId}`;
    return this.http.get<PaylinkDetails>(url).pipe(
      catchError((error) => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener el detalle');
        return throwError(() => new Error(error));
      }),
    );
  }

}
