import { Component, inject, Input, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyService } from '@shared/services/currency.service';
import { Plan } from 'src/app/tenant/plans/models/form.models';
import { PlansServices } from 'src/app/tenant/plans/service/plans.service';
import { ProductServices } from 'src/app/tenant/products/services/product.service';
import { GeneralStatus, STATUS_CLASS_MAP, STATUS_TRANSLATIONS } from '@shared/models/enum-status.models';

@Component({
  selector: 'app-modal-plans',
  templateUrl: './modal-plans.component.html',
  styleUrl: './modal-plans.component.scss'
})
export class ModalPlansComponent implements OnInit {
  @Input() planId!: string;
  @Input() merchantId!: string;
  listPlans!: Plan;
  listProduc: any;

  public activeModal = inject(NgbActiveModal);
  private plansServices = inject(PlansServices);
  private productServices = inject(ProductServices);
  private currencyService = inject(CurrencyService);
  private currencyPipe = inject(CurrencyPipe);

  ngOnInit(): void {
    this.getDetailsPlans();
    this.getAll();
  }

  getDetailsPlans(): void {
    this.plansServices.idPlans(this.planId, this.merchantId).subscribe(response => {
      this.listPlans = response;
    });
  }

  getAll(): void {
    this.productServices.getAllProducts(this.merchantId).subscribe(response => {
      this.listProduc = response;
    });
  }


  public getStatusClass(status: GeneralStatus): string {
    return STATUS_CLASS_MAP[status] || "badge-soft-primary";
  }

  public getStatusInSpanish(status: GeneralStatus): string {
    return STATUS_TRANSLATIONS[status] || "Desconocido";
  }

  getCurrencySymbol(currencyCode: string): string {
    return this.currencyService.getCurrencySymbol(currencyCode);
  }

  formatAmount(value: number, currency: string): string {
    if (currency === 'PYG') {
      return parseFloat(value.toString()).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return this.currencyPipe.transform(value?.toString(), '', '', '1.2-2');
  }

  public getFrequencyInSpanish(frequency: string): string {
    const frequencyMap: { [key: string]: string } = {
      MONTHLY: "Mensual",
      WEEKLY: "Semanal",
      BIWEEKLY: "Quincenal",
      YEARLY: "Anual"
    };

    return frequencyMap[frequency] || "Desconocido";
  }


}
