import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AlertsService } from './alerts.service';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
  constructor(
    private httpClient: HttpClient,
    private alerts: AlertsService
  ) { }

  checkUrl(url: string): Observable<any> {
    return this.httpClient.head(url, {observe: 'response', responseType: 'text'});
  }

  copyToClipboard(text: string, selector?: string, fromDiv = false) {
    let generated = this.isNil(selector);
    let result;
    let textarea;

    if (generated) {
        let timestamp = Date.now();
        selector = `copy_textarea_${timestamp}`;
        //Create the element using the createElement method.
        textarea = document.createElement('textarea');

        //Set its unique ID.
        textarea.id = selector;
        textarea.style.cssText = 'position: absolute; top: -200px; left: -200px;';
        textarea.classList.add('readonly');
        textarea.value = text;
        document.body.appendChild(textarea);
    }

    if (fromDiv) {
        this.selectDivText(selector);
    } else {
        textarea = document.querySelector(`#${selector}`) as any;
        textarea.select();
    }

    try {
      let successful = document.execCommand('copy');
      result = { text, successful };
    } catch (err) {
      this.alerts.error('Copiar', 'Error al copiar texto.');
      result = { successful: false, error: err };
    }

    if (generated) {
      document.body.removeChild(textarea);
    }

    return result;
  }

  downloadFromLink(url: string, filename?: string): void {
    let element = document.createElement('a');
    element.setAttribute('href', url);
    if (filename) {
        element.setAttribute('download', filename);
    }
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  generateCitation(format, citations, selectedText?: string, currentPage?: number) {
    let citation = selectedText ? selectedText + '\n' : '';
    return {
        text: citation + citations[format].text.replace('title_url', this.getLink(currentPage)),
        message: citations[format].message
    };
  }

  getLink(currentPage?: number) {
    let location = window.location;
    let link = location.protocol + '//' + location.host + location.pathname;
    if (currentPage) {
      return `${link}?page=${currentPage}`;
    } else {
      return link;
    }
  }

  /**
   * Gets the `toStringTag` of `value`.
   *
   * @private
   * @param {*} value The value to query.
   * @returns {string} Returns the `toStringTag`.
   */
  getTag(value: any): string {
    if (this.isNil(value)) {
      return value === undefined ? '[object Undefined]' : '[object Null]'
    }
    return Object.prototype.toString.call(value)
  }

  isEmpty(value: any) {
    if(this.isNil(value)) {
      return true;
    }

    if ((Array.isArray(value) || typeof value === 'string' || typeof value.splice === 'function')) {
      return !value.length;
    }

    const tag = this.getTag(value)
    if (tag == '[object Map]' || tag == '[object Set]') {
      return !value.size;
    }

    if (this.isPrototype(value)) {
      return !Object.keys(value).length
    }

    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        return false
      }
    }

    return true
  }

  isNil(value: any): boolean {
    return value === null || value === undefined;
  }

  isPrototype(value) {
    const Ctor = value && value.constructor;
    const proto = (typeof Ctor === 'function' && Ctor.prototype) || Object.prototype;

    return value === proto;
  }

  padStartNumber(wordNumber: number, numberOfDigits?: number): string {
    let stringWordNumber = wordNumber.toString();
    numberOfDigits = this.isNil(numberOfDigits) ? 3 : numberOfDigits;

    return this.repeat('0', numberOfDigits - stringWordNumber.length) + stringWordNumber;
  }

  repeat(str: string, n: number): string {
    let result = ''
    if (!str || n < 1 || n > Number.MAX_SAFE_INTEGER) {
      return result
    }

    // Leverage the exponentiation by squaring algorithm for a faster repeat.
    // See https://en.wikipedia.org/wiki/Exponentiation_by_squaring for more details.
    do {
      if (n % 2) {
        result += str;
      }
      n = Math.floor(n / 2);
      if (n) {
        str += str;
      }
    } while (n);

    return result
  }

  reverseMap(obj: any): any {
    return Object.keys(obj).reduce((result: any, key: string) => {
      const value = obj[key];
      result[value] = key;
      return result;
    }, {});
  }

  selectDivText(selector: string): void {
    if ((document as any).selection) { // IE
      const range = (document.body as any).createTextRange();
      range.moveToElementText(document.getElementById(selector));
      range.select();
    } else if (window.getSelection) {
      const range = document.createRange();
      range.selectNode(document.getElementById(selector));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
  }


  toBoolean(value: boolean | string): boolean {
    return value != null && `${value}` !== 'false';;
  }

  upperFirst(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  snakeToTitleCase(s: string): string {
    return s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())
  }

  isMobile(): boolean {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
  }
  
  deepEqual(a: any, b: any): boolean {
    if (a === b) return true;

    if (a == null || typeof a != 'object' || b == null || typeof b != 'object') return false;

    const keysA = Object.keys(a), keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    for (let key of keysA) {
      if (!keysB.includes(key) || !this.deepEqual(a[key], b[key])) return false;
    }

    return true;
  }

  removeTextAccents(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
