import { Component, inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileProcessing } from 'src/app/tenant/charges/models/charges.models';
import { ChargesService } from 'src/app/tenant/charges/services/charges.service';
import { saveAs } from 'file-saver';
import { AlertsService } from '@shared/services/alerts.service';
import { HttpClient } from '@angular/common/http';
import { GeneralStatus, STATUS_CLASS_MAP, STATUS_TRANSLATIONS } from '@shared/models/enum-status.models';
import { User } from '@shared/models/user.models';
import { AuthService } from '@shared/services/auth.service';

@Component({
  selector: 'app-modal-charges',
  templateUrl: './modal-charges.component.html',
  styleUrl: './modal-charges.component.scss'
})
export class ModalChargesComponent implements OnInit {
  @Input() uuid!: string;
  detailCharge!: FileProcessing;
  fileName: string = '';
  user: User;
  merchantId;


  public activeModal = inject(NgbActiveModal);
  private chargesService = inject(ChargesService);
  private alerts = inject(AlertsService);
  private authService = inject(AuthService);
  private http = inject(HttpClient);

  ngOnInit(): void {
    this.getAll();

    this.user = this.authService.currentUser;
    this.merchantId = this.user.chosenMerchant.merchantId;
  }

  getAll(): void {
    this.chargesService.getProcesFile(this.uuid).subscribe(response => {
      this.detailCharge = response;
    });
  }


  public getStatusClass(status: GeneralStatus): string {
    return STATUS_CLASS_MAP[status] || "badge-soft-primary";
  }

  public getStatusInSpanish(status: GeneralStatus): string {
    return STATUS_TRANSLATIONS[status] || "Desconocido";
  }

  public getChargeTypeInSpanish(type: string): string {
    const TYPE_TRANSLATIONS: Record<string, string> = {
      CHARGES: 'Cargos',
      SUBSCRIPTION: 'Suscripción'
    };
    
    return TYPE_TRANSLATIONS[type] || 'Desconocido';
  }
 
  public getChargeDetailsTitle(): string {
    return this.detailCharge?.type === 'SUBSCRIPTION' ? 'Detalles de la Suscripción' : 'Detalles del Cargo';
  }
  
  public getChargeDetailsText(): string {
    return this.detailCharge?.type === 'SUBSCRIPTION' ? 'Datos de la Suscripción' : 'Datos del Cargo';
  }
  


  onDownload(): void {

    const updatedUrl = this.detailCharge.url.replace('/in/', '/out/');
    const urlParts = this.detailCharge.url.split('/');
    const encodedFileName = urlParts[urlParts.length - 1];
    let fileName = decodeURIComponent(encodedFileName);
    fileName = fileName.replace('.xlsx', '.csv');
    const fileType = this.detailCharge.type.toLowerCase();

    this.chargesService.urlDownloadCharges(this.merchantId, fileType, fileName).subscribe(data => {
      const blob = new Blob([data], { type: 'text/csv' });
      saveAs(blob, fileName);
      this.activeModal.close();
    }, (error) => {
      if (error.status === 403) {
        this.alerts.success('ERROR 403: Verifica las reglas CORS del bucket y los permisos.');
      } else if (error.status === 400) {
        this.alerts.success('ERROR 400: Puede ser que no haya subido archivos');
      }
      this.alerts.error('Hubo un problema al descargar el archivo.');
    });
  }
}
