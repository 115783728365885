import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MerchantsServices } from "src/app/tenant/merchants/services/merchants.service";
import { AlertsService } from '@shared/services/alerts.service';

@Component({
  selector: 'app-btn-group',
  styleUrls: ['./btn-group.component.scss'],
  template: `
    <div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
      <input type="radio" class="btn-check" name="{{params.data?.id}}" id="{{params.data?.id}}-active" autocomplete="off" [checked]="isActive">
      <label class="btn btn-outline-primary" for="{{params.data?.id}}-active" (click)="onStatusChange(true)">Activo</label>
      <input type="radio" class="btn-check" name="{{params.data?.id}}" id="{{params.data?.id}}-inactive" autocomplete="off" [checked]="!isActive">
      <label class="btn btn-outline-danger" for="{{params.data?.id}}-inactive" (click)="onStatusChange(false)">Inactivo</label>
    </div>
  `
})
export class BtnGroupComponent implements ICellRendererAngularComp, OnInit {
  public params!: any;
  public isActive!: boolean;
  @Input() onActivate!: (isActive: boolean) => void;

  constructor(
    private merchantServices: MerchantsServices,
    private alert: AlertsService
  ){}

  agInit(params: any): void {
    this.params = params;
    this.isActive = params.data?.status === true;
  }

  ngOnInit(): void {}

  refresh(params: any): boolean {
    this.params = params;
    this.isActive = params.data?.status === true;
    return true;
  }

  onStatusChange(status: boolean) {
    if (this.isActive === status) {
      return;
    }

    this.isActive = status;

    const updatedStatus = status ? 'ACTIVE' : 'INACTIVE';

    this.merchantServices.updateStatus(this.params.data.merchantId, status).subscribe(
      (response) => {
        this.params.data.status = updatedStatus;
        this.alert.success('Estado actualizado correctamente');
      },
      (error) => {
        this.alert.error('Error al actualizar el estado');
      }
    );
  }
}