import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CatalogGuard } from '@shared/guards/catalog.guard';
import { Page404Component } from './pages/page404/page404.component';
import { RoleGuard } from '@shared/guards/level.guard';
import { AuthGuard } from '@shared/guards/auth.guard';

const routes: Routes = [
  {
    path: "not-found",
    component: Page404Component,
  },
  {
    path: ':tenant/login', loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    canActivate: [CatalogGuard]
  },

  // NOTE: This route can be problematic in some cases,
  // Make sure to add all routes not related to library module above this route
  {
    path: ":tenant",
    loadChildren: () =>
      import("./tenant/tenant.module").then((m) => m.TenanModule),
    canActivate: [CatalogGuard, RoleGuard, AuthGuard],
    canLoad: [CatalogGuard, RoleGuard, AuthGuard],
  },
  { path: '**', component: Page404Component }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
