import { Component, inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralStatus, STATUS_CLASS_MAP, STATUS_TRANSLATIONS } from '@shared/models/enum-status.models';
import { Product } from 'src/app/tenant/products/models/form.models';
import { ProductServices } from 'src/app/tenant/products/services/product.service';

@Component({
  selector: 'app-modal-product',
  templateUrl: './modal-product.component.html',
  styleUrl: './modal-product.component.scss'
})
export class ModalProductComponent implements OnInit {
  @Input() productId!: string;
  @Input() merchantId!: string;
  listProducts!: Product;

  public activeModal = inject(NgbActiveModal);
  private productServices = inject(ProductServices);


  ngOnInit(): void {
    this.getDetailsProduct()
  }

  getDetailsProduct(): void {
    this.productServices.idProduct(this.productId, this.merchantId).subscribe(response => {
      this.listProducts = response.data;
    });
  }

  public getStatusClass(status: GeneralStatus): string {
    return STATUS_CLASS_MAP[status] || "badge-soft-primary";
  }

  public getStatusInSpanish(status: GeneralStatus): string {
    return STATUS_TRANSLATIONS[status] || "Desconocido";
  }

}
