import { NgModule } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { DataTableComponent } from './components/data-table/data-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BaseModalComponent } from './components/modal/modal.component';
import { AgGridAngular } from 'ag-grid-angular';

import { LinkCellRendererComponent } from './components/data-table/components/link-cell-renderer.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FiltersComponent } from './components/data-table/components/filters/filters.component';
import { NgbAccordionModule, NgbTypeaheadModule, NgbDatepickerModule, NgbAlertModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ColumnsComponent } from './components/data-table/components/columns/columns.component';
import { RouterModule } from '@angular/router';
import { ButtonCellRendererComponent } from './components/data-table/components/button-cell-renderer/button-cell-renderer.component';
import { ModalDetailMerchantsComponent } from './components/data-table/components/modal-detail-merchants/modal-detail-merchants.component';
import { ModalAnnulmentComponent } from './components/data-table/components/modal-annulment/modal-annulment.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdwonCellRendererComponent } from './components/data-table/components/dropdwon-cell-renderer/dropdwon-cell-renderer.component';
import { ModalDetailPayLinkComponent } from './components/data-table/components/modal-detail-pay-link/modal-detail-pay-link.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ModaDetailPaylinkComponent } from './components/data-table/components/moda-detail-paylink/moda-detail-paylink.component';
import { ModalDetailRechargeComponent } from './components/data-table/components/modal-detail-recharge/modal-detail-recharge.component';
import { ModalDetailSalesComponent } from './components/data-table/components/modal-detail-sales/modal-detail-sales.component';
import { BtnGroupComponent } from './components/data-table/components/btn-group/btn-group.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { ModalReversedBalanceComponent } from './components/data-table/components/modal-reversed-balance/modal-reversed-balance.component';
import { ModalLogSalesComponent } from './components/data-table/components/modal-log-sales/modal-log-sales.component';
import { ModalThirdPartyLogsComponent } from './components/data-table/components/modal-third-party-logs/modal-third-party-logs.component';
import { DynamicFormFieldComponent } from './components/dynamic-form/dynamic-form-field/dynamic-form-field.component';
import { LazyLoadingSelectComponent } from './components/lazy-loading-select/lazy-loading-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalDetailUserComponent } from './components/modals/modal-detail-user/modal-detail-user.component';
import { TrimSpacesDirective } from './components/dynamic-form/trim-spaces.directive';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalSubcriptionComponent } from './components/data-table/components/modal-subcription/modal-subcription.component';
import { ModalProductComponent } from './components/data-table/components/modal-product/modal-product.component';
import { ModalPlansComponent } from './components/data-table/components/modal-plans/modal-plans.component';
import { FileUploaderComponent } from './components/dynamic-form/dynamic-form-field/file-uploader/file-uploader.component';
import { ModalChargesComponent } from './components/data-table/components/modal-charges/modal-charges.component';
import { ModalDesactiveComponent } from './components/data-table/components/modal-desactive/modal-desactive.component';




const sharedComponents = [
  DataTableComponent,
  BaseModalComponent,
  FiltersComponent,
  ColumnsComponent,
  ButtonCellRendererComponent,
  ModalDetailMerchantsComponent,
  ModalAnnulmentComponent,
  ModalDetailPayLinkComponent,
  DropdwonCellRendererComponent,
  ModaDetailPaylinkComponent,
  ModalDetailRechargeComponent,
  ModalDetailSalesComponent,
  BtnGroupComponent,
  ModalReversedBalanceComponent,
  ModalLogSalesComponent,
  ModalThirdPartyLogsComponent,
  DynamicFormComponent,
  DynamicFormFieldComponent,
  LazyLoadingSelectComponent,
  ModalDetailUserComponent,
  TrimSpacesDirective,
  ModalSubcriptionComponent,
  ModalProductComponent,
  ModalPlansComponent,
  FileUploaderComponent,
  ModalChargesComponent,
  ModalDesactiveComponent,
];
const agGridComponents = [
  LinkCellRendererComponent
];

@NgModule({
  declarations: [
    ...sharedComponents,
 
 ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AgGridAngular, 
    NgbAccordionModule,
    NgbDropdownModule,
    AgGridAngular,
    RouterModule,
    NgbPopoverModule,
    NgbTypeaheadModule,
    NgbAlertModule,
    JsonPipe,
    NgSelectModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    BsDatepickerModule.forRoot(),
    ShareButtonsModule.withConfig({
      theme: 'modern-dark', // Asegúrate de que el tema esté configurado aquí si es necesario
    }),

  ],
  exports: [
    ...sharedComponents,
    FormsModule,
    ReactiveFormsModule
  ]
})

export class SharedModule { }
