
<multitenant-base-modal>
    <div class="modal-title d-flex align-items-center mb-2 justify-content-center">
      <i class="bx bx-detail text-success small-icon"></i>
      <h4 class="ms-2 mb-0 custom-button">Detalle de la Suscripción</h4>
    </div>
    <div class="body-container">
      <div class="tab-content" id="nav-tabContent" style="position: relative;top: 10px;">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
          <div class="card colors-cards">
            <p class="tab-text-commerm">Datos de la Suscripción</p>
            <div class="card-body">
              <div class="btn-container">
                <label class="text-commerm">Código:</label>
                <label class="text-rest">{{ listSus?.subscriptionId }}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm"># Documento:</label>
                <label class="text-rest">{{ listSus?.documentId }}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Nombre:</label>
                <label class="text-rest">{{ listSus?.fullName}}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Código del Plan:</label>
                <label class="text-ruc">{{ listSus?.planId }}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Nombre del Plan:</label>
                <label class="text-ruc">{{ listSus?.planName }}</label>
              </div>
              
              
              <div class="btn-container">
                <label class="text-commerm">Fecha de Creación:</label>
                <label class="text-ruc">{{ listSus?.createdOn | date:'dd/MM/yyyy HH:mm:ss' }}</label>
              </div>

              <div class="btn-container">
                <label class="text-commerm">Siguiente Fecha de cobro:</label>
                <label class="text-ruc">{{ listSus?.nextChargeDate | date:'dd/MM/yyyy HH:mm:ss'  }}</label>
              </div>

              <div class="btn-container">
                <label class="text-commerm">Proximo Cobro:</label>
                <label class="text-ruc">
                {{ getCurrencySymbol(listSus?.currency) }} {{ formatAmount(listSus?.nextChargeAmount, listSus?.currency) }}
                </label>
             </div>

              <div class="btn-container">
                <label class="text-commerm">Ultima Fecha de cobro:</label>
                <label class="text-ruc">{{ listSus?.lastChargeDate | date:'dd/MM/yyyy HH:mm:ss' }}</label>
              </div>

              <div class="btn-container">
                <label class="text-commerm">Ultimo Cobro:</label>
                <label class="text-ruc">
                  {{ getCurrencySymbol(listSus?.currency) }} {{ formatAmount(listSus?.lastChargeAmount, listSus?.currency) }}

                </label>
              </div> 
              <div class="btn-container">
                <label class="text-commerm">Estado :</label>
                <span [ngClass]="[getStatusClass(listSus?.status),'no-background']">
                  <label class="text-center-status">{{getStatusInSpanish(listSus?.status)}}</label> </span>
              </div>
            </div>
          </div>
        </div> 
      </div> 
    </div> 
  
    <div class="modal-footer justify-content-center">
      <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">Cerrar</button>
    </div>
</multitenant-base-modal>