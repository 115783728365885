import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { TenantService } from '@shared/services/tenant.service';
import { UserService } from '@shared/services/user.service';
import { Subscription } from '../models/form.models';
import { AlertsService } from '@shared/services/alerts.service';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private http: HttpClient,
    private tenantService: TenantService,
    private userService: UserService,
    private alerts: AlertsService
  ) { }


  createSession(): Observable<string> {
    const user = this.userService.currentUser;
    return this.http.post<any>(
      `${this.tenantService.api}/product/session/create`, {
      merchantId: user.chosenMerchant.merchantId
    }
    ).pipe(
      map((data: any) => data.url),
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error en createSession');
        return throwError(() => error);
      })
    );
  }

  idSuscription(idSuscription: string): Observable<Subscription> {
    const url = `${this.tenantService.api}/subscription-rec/${idSuscription}`;
    return this.http.get<Subscription>(url).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener la lista');
        return throwError(() => error);
      })
    );
  }

  desacSubcription(codeSubscrition: string, status: string): Observable<any | undefined> {
    const url = `${this.tenantService.api}/product/suscription/update-status`;
    const body = {
      subscriptionId: codeSubscrition,
      status: status
    }
    return this.http.put<any>(url, body).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al desactivar la subcripción');
        return throwError(() => error);
      })
    );
  }

  urlPresignedSubcription(merchantId: string, fileName: string): Observable<any> {
    const url = `${this.tenantService.api}/merchant/presigned-url`;
    const body = {
      relativePath: `${merchantId}/subscription/in/${fileName}`
    };
    return this.http.post<any>(url, body).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al subir archivo');
        return throwError(() => error);
      })
    )
  }

  sendFileUrl(urlFile: string, merchantId: String, type: string): Observable<any> {
    const url = `${this.tenantService.api}/file`;
    const body = { url: urlFile, merchantId, type }
    return this.http.post<any>(url, body).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al subir la url');
        return throwError(() => error);
      })
    )

  }

}
