<multitenant-base-modal>
  <div class="modal-title d-flex">
    <div class="d-flex align-items-center mb-2 justify-content-center">
      <i class="fas fa-check-double text-success small-icon"></i>
      <h5 class="ms-2 mb-0 custom-button" *ngIf="init === 'create'">¡Pago Link creado con éxito!</h5>
      <h5 class="ms-2 mb-0 custom-button" *ngIf="init === 'preview'">¡Compartir Pago Link!</h5>
    </div>
  </div>
  <p class="mt-2">Copia el link de pago y comparte con quién quieras.</p>
  <form [formGroup]="linkForm" (ngSubmit)="onSubmit()" class="p-3">
    <div class="input-group mb-3">
      <input formControlName="link" type="text" class="form-control" readonly>
      <div class="input-group-append">
        <button class="btn btn-primary btn-block me-2 custom-button" type="button" (click)="copyLink()">
          <i class="fas fa-copy"></i>
        </button>
        <div class="btn-group">
          <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <a class="share-button whatsapp" href="https://wa.me/?text={{linkForm.get('link').value}}" target="_blank">
              <i class="fab fa-whatsapp"></i>
            </a>
            <a class="share-button email" href="mailto:?subject=Check%20this%20out&body={{linkForm.get('link').value}}" target="_blank">
              <i class="fas fa-envelope"></i>
            </a>
          </button>
        </div>
        <button class="btn btn-primary btn-block ms-2 custom-button" type="button" (click)="generateQRCode()">
          <i class="fas fa-qrcode"></i> QR
        </button>
      </div>
    </div>
    <div *ngIf="qrCodeDataUrl" class="text-center mt-3">
      <img [src]="qrCodeDataUrl" alt="QR Code">
    </div>
  </form>
</multitenant-base-modal>