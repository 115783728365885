import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { CatalogService } from "@shared/services/catalog.service";
import { AuthService } from "@shared/services/auth.service";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CatalogGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private catalog: CatalogService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canAccess(route.paramMap.get('tenant'), state.url);
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean | UrlTree> {
    const urlTree = this.router.getCurrentNavigation().extractedUrl;
    return this.canAccess(segments[0]?.path, this.router.serializeUrl(urlTree));
  }

  private canAccess(client: string | undefined, next: string): boolean | Observable<boolean | UrlTree> {
    if (this.catalog.validClient(client)) {
      return true;
    }

    return this.catalog.setServer(client).pipe(
      take(1),
      map(serverFound => {
        if (!serverFound) {
          this.authService.logout(false);
          return this.router.parseUrl(`/not-found?next=${next}`);
        }
        return true;
      })
    );
  }
}
