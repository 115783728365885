import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface RightView {
  view: string;
  columns?: any;
  filters?: any;
}

export interface RightViewFilter {
  view: string;
  filters: any;
}

@Injectable({
  providedIn: 'root'
})
export class RightsidebarService {
  private rightviewSubject = new Subject<RightView>();
  rightview$ = this.rightviewSubject.asObservable().pipe(
    tap(() => {
      document.body.classList.toggle('right-bar-enabled');
    })
  );

  private rightviewSelectFiltersSubject = new Subject<RightViewFilter>();
  rightviewSelectFilters$ = this.rightviewSelectFiltersSubject.asObservable().pipe(
    tap(() => {
      document.body.classList.toggle('right-bar-enabled');
    })
  );

  emitRightView(view: RightView) {
    this.rightviewSubject.next(view);
  }

  emitRightViewFilter(filter: RightViewFilter) {
    this.rightviewSelectFiltersSubject.next(filter);
  }
}
