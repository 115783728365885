// This is the way ag-grid define locales (Check https://www.ag-grid.com/javascript-data-grid/localisation/)
export const AG_GRID_LOCALE_ES = {
  // Set Filter
  selectAll: '(Seleccionar todo)',
  selectAllSearchResults: '(Seleccione todos los resultados de búsqueda)',
  searchOoo: 'Búsqueda...',
  blanks: '(espacios en blanco)',
  noMatches: 'No hay coincidencias',

  // Number Filter & Text Filter
  filterOoo: 'Filtrar...',
  equals: 'igual',
  notEqual: 'No es igual',
  blank: 'Espacio en blanco',
  notBlank: 'no en blanco',
  empty: 'Elige uno',

  // Number Filter
  lessThan: 'Menos que',
  greaterThan: 'Mas grande que',
  lessThanOrEqual: 'Menor o igual',
  greaterThanOrEqual: 'Mayor que o igual',
  inRange: 'En el rango',
  inRangeStart: 'desde',
  inRangeEnd: 'a',

  // Text Filter
  contains: 'Contiene',
  notContains: 'no contiene',
  startsWith: 'Comienza con',
  endsWith: 'Termina con',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',

  // Filter Conditions
  andCondition: 'Y',
  orCondition: 'O',

  // Filter Buttons
  applyFilter: 'Aplicar',
  resetFilter: 'Reiniciar',
  clearFilter: 'Claro',
  cancelFilter: 'Cancelar',

  // Filter Titles
  textFilter: 'Filtro de texto',
  numberFilter: 'Filtro de número',
  dateFilter: 'Filtro de fecha',
  setFilter: 'Establecer filtro',

  // Side Bar
  columns: 'Columnas',
  filters: 'Filtros',
  board: 'Tablero',

  // columns tool panel
  pivotMode: 'Modo de pivote',
  groups: 'Grupos de filas',
  rowGroupColumnsEmptyMessage: 'Arrastre aquí para establecer grupos de filas',
  values: 'Valores',
  valueColumnsEmptyMessage: 'Arrastre aquí para agregar',
  pivots: 'Etiquetas de columna',
  pivotColumnsEmptyMessage: 'Arrastre aquí para establecer etiquetas de columna',

  // Header of the Default Group Column
  group: 'Grupo',

  // Row Drag
  rowDragRows: 'filas',

  // Other
  loadingOoo: 'Cargando...',
  noRowsToShow: 'No hay filas para mostrar',
  enabled: 'Activado',

  // Menu
  pinColumn: 'Anclar columna',
  pinLeft: 'Anclar a la izquierda',
  pinRight: 'Anclar a la derecha',
  noPin: 'Remover anclaje',
  valueAggregation: 'Agregación de valor',
  autosizeThiscolumn: 'Cambiar a tamaño automático esta columna',
  autosizeAllColumns: 'Cambiar a tamaño automático de todas las columnas',
  groupBy: 'Agrupar por',
  ungroupBy: 'Desagrupar por',
  addToValues: 'Agregar ${variable} a los valores',
  removeFromValues: 'Eliminar ${variable} de los valores',
  addToLabels: 'Agregar ${variable} a las etiquetas',
  removeFromLabels: 'Eliminar ${variable} de las etiquetas',
  resetColumns: 'Restablecer columnas',
  expandAll: 'Expandir todo',
  collapseAll: 'Cierra todo',
  copy: 'Dupdo',
  ctrlC: 'Ctrl + C',
  copyWithHeaders: 'Copiar con encabezados',
  copyWithHeaderGroups: 'Copiar con grupos de encabezado',
  paste: 'Pegar',
  ctrlV: 'Control+V',
  export: 'Exportar',
  csvExport: 'Exportar a CSV',
  excelExport: 'Exportar a Excel',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Suma',
  min: 'Min',
  max: 'Máx',
  none: 'None',
  count: 'Contar',
  avg: 'Promedio',
  filteredRows: 'Filtrado',
  selectedRows: 'Seleccionado',
  totalRows: 'Total de filas',
  totalAndFilteredRows: 'Filas',
  more: 'Más',
  to: 'al',
  of: 'de',
  page: 'Página',
  nextPage: 'Siguiente página',
  lastPage: 'Última página',
  firstPage: 'Primera página',
  previousPage: 'Pagina anterior',

  // Pivoting
  pivotColumnGroupTotals: 'Total',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Gráfico dinámico y modo de pivote',
  pivotChart: 'Gráfico dinámico',
  chartRange: 'Rango de gráfico',

  columnChart: 'Columna',
  groupedColumn: 'Agrupados',
  stackedColumn: 'Apilado',
  normalizedColumn: '100% apilado',

  barChart: 'Bar',
  groupedBar: 'Agrupados',
  stackedBar: 'Apilado',
  normalizedBar: '100% apilado',

  pieChart: 'Pie',
  pie: 'Pie',
  doughnut: 'Doughnut',

  line: 'Línea',

  xyChart: 'X Y (Dispersión)',
  scatter: 'Dispersión',
  bubble: 'Burbuja',

  areaChart: 'Área',
  area: 'Área',
  stackedArea: 'apilado',
  normalizedArea: '100% apilado',

  histogramChart: 'Histograma',

  combinationChart: 'Combinación',
  columnLineCombo: 'Columna y línea',
  AreaColumnCombo: 'Área y columna',

  // Charts
  pivotChartTitle: 'Gráfico dinámico',
  rangeChartTitle: 'Gráfico de rango',
  settings: 'Ajustes',
  data: 'Datos',
  format: 'Formato',
  categories: 'Categorías',
  defaultCategory: '(Ninguna)',
  series: 'Serie',
  xyValues: 'Valores XY',
  paired: 'Modo emparejado',
  axis: 'Eje',
  navigator: 'Navegador',
  color: 'Color',
  thickness: 'Grosor',
  xType: 'Tipo X',
  automatic: 'Automático',
  category: 'Categoría',
  number: 'Número',
  time: 'Tiempo',
  xRotation: 'Rotación X',
  yRotation: 'Rotación Y',
  ticks: 'garrapatas',
  width: 'Ancho',
  height: 'Altura',
  length: 'Largo',
  padding: 'Relleno',
  spacing: 'Espaciado',
  chart: 'Gráfico',
  title: 'Título',
  titlePlaceholder: 'Título del gráfico: haga doble clic para editar',
  background: 'Fondo',
  font: 'Fuente',
  top: 'Cima',
  right: 'Derecha',
  bottom: 'Abajo',
  left: 'Izquierda',
  labels: 'Etiquetas',
  size: 'Tamaño',
  minSize: 'Talla minima',
  maxSize: 'Talla máxima',
  legend: 'Leyenda',
  position: 'Posición',
  markerSize: 'Tamaño del marcador',
  markerStroke: 'Trazo de marcador',
  markerPadding: 'Relleno de marcador',
  itemSpacing: 'Espaciado de elementos',
  itemPaddingX: 'Artículo relleno X',
  itemPaddingY: 'Artículo relleno Y',
  layoutHorizontalSpacing: 'Espaciado horizontal',
  layoutVerticalSpacing: 'Espaciado vertical',
  strokeWidth: 'Anchura del trazo',
  offset: 'Compensar',
  offsets: 'Compensaciones',
  tooltips: 'Información sobre herramientas',
  callout: 'Gritar',
  markers: 'marcadores',
  shadow: 'Sombra',
  blur: 'Difuminar',
  xOffset: 'Desplazamiento X',
  yOffset: 'Desplazamiento Y',
  lineWidth: 'Grosor de línea',
  normal: 'Normal',
  bold: 'Audaz',
  italic: 'Itálico',
  boldItalic: 'Negrita cursiva',
  predefined: 'Predefinido',
  fillOpacity: 'Opacidad de relleno',
  strokeOpacity: 'Opacidad de línea',
  histogramBinCount: 'Recuento de contenedores',
  columnGroup: 'Columna',
  barGroup: 'Barra',
  pieGroup: 'Pie',
  lineGroup: 'Línea',
  scatterGroup: 'X Y (Dispersión)',
  areaGroup: 'Área',
  histogramGroup: 'Histograma',
  combinationGroup: 'Combinación',
  groupedColumnTooltip: 'agrupados',
  stackedColumnTooltip: 'apilado',
  normalizedColumnTooltip: '100% apilado',
  groupedBarTooltip: 'agrupados',
  stackedBarTooltip: 'apilado',
  normalizedBarTooltip: '100% apilado',
  pieTooltip: 'Pie',
  doughnutTooltip: 'Doughnut',
  lineTooltip: 'Línea',
  groupedAreaTooltip: 'Área',
  stackedAreaTooltip: 'apilado',
  normalizedAreaTooltip: '100% apilado',
  scatterTooltip: 'Dispersión',
  bubbleTooltip: 'Burbuja',
  histogramTooltip: 'Histograma',
  columnLineComboTooltip: 'Columna y línea',
  areaColumnComboTooltip: 'Área y columna',
  customComboTooltip: 'Combinación personalizada',
  noDataToChart: 'No hay datos disponibles para ser graficados.',
  pivotChartRequiresPivotMode: 'El gráfico dinámico requiere que el modo pivote esté habilitado.',
  chartSettingsToolbarTooltip: 'Menú',
  chartLinkToolbarTooltip: 'Vinculado a la cuadrícula',
  chartUnlinkToolbarTooltip: 'Desvinculado de la cuadrícula',
  chartDownloadToolbarTooltip: 'Descargar gráfico',
  seriesChartType: 'Tipo de gráfico de serie',
  seriesType: 'Tipo de serie',
  secondaryAxis: 'Eje secundario',

  // ARIA
  ariaHidden: 'oculto',
  ariaVisible: 'visible',
  ariaChecked: 'comprobado',
  ariaUnchecked: 'desenfrenado',
  ariaIndeterminate:'indeterminate',
  ariaDefaultListName: 'Lista',
  ariaColumnSelectAll: 'Toggle Seleccionar todas las columnas',
  ariaInputEditor: 'Editor de entrada',
  ariaDateFilterInput: 'Entrada de filtro de fecha',
  ariaFilterList: 'Lista de filtros',
  ariaFilterInput: 'Entrada de filtro',
  ariaFilterColumnsInput: 'Entrada de columnas de filtro',
  ariaFilterValue: 'Valor de filtro',
  ariaFilterFromValue: 'Filtrar por valor',
  ariaFilterToValue: 'Filtrar por valor',
  ariaFilteringOperator: 'Operador de filtrado',
  ariaColumn: 'Columna',
  ariaColumnList: 'Lista de columnas',
  ariaColumnGroup: 'Grupo de columnas',
  ariaRowSelect: 'Presiona ESPACIO para seleccionar esta fila',
  ariaRowDeselect: 'Presiona ESPACIO para anular la selección de esta fila',
  ariaRowToggleSelection: 'Presione la barra espaciadora para alternar la selección de filas',
  ariaRowSelectAll: 'Presione Espacio para alternar la selección de todas las filas',
  ariaToggleVisibility: 'Presiona ESPACIO para alternar la visibilidad',
  ariaSearch: 'Búsqueda',
  ariaSearchFilterValues: 'Valores de filtro de búsqueda',

  ariaRowGroupDropZonePanelLabel: 'Grupos de filas',
  ariaValuesDropZonePanelLabel: 'Valores',
  ariaPivotDropZonePanelLabel: 'Etiquetas de columna',
  ariaDropZoneColumnComponentDescription: 'Presione ELIMINAR para eliminar',
  ariaDropZoneColumnValueItemDescription: 'Presione ENTER para cambiar el tipo de agregación',

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: 'Menú de columna',
  ariaLabelCellEditor: 'Editor de celdas',
  ariaLabelDialog: 'Diálogo',
  ariaLabelSelectField: 'Seleccionar campo',
  ariaLabelTooltip: 'Información sobre herramientas',
  ariaLabelContextMenu: 'Menú de contexto',
  ariaLabelSubMenu: 'Submenú',
  ariaLabelAggregationFunction: 'Función de agregación',

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ',',
  decimalSeparator: '.'
};
