import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';


import {
  LAYOUT_WIDTH, TOPBAR, LayoutType,
} from './layouts.model';
import { LayoutService } from './services/layout.service';
import { EventService } from '@shared/services/event.service';

@Component({
  selector: 'multitenant-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit, OnDestroy {
  @Input() type: LayoutType;
  layoutwidth: string;
  topbar: string;
  layoutTypes = LayoutType;
  private destroy$ = new Subject<void>();

  constructor(
    private eventService: EventService,
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.layoutwidth = LAYOUT_WIDTH;
    this.topbar = TOPBAR;
    this.layoutService.setLayoutType(this.type);
    // Taken from the theme code
    this.eventService.subscribe('changeWidth', (width) => {
      this.layoutwidth = width;
      this.updateLayoutWidth(this.layoutwidth);
    });
  }

  ngOnDestroy() {
    document.body.removeAttribute("data-layout");
    document.body.removeAttribute("data-layout-size");
    document.body.removeAttribute("data-layout-scrollable");

    this.destroy$.next();
    this.destroy$.complete();
  }

  updateLayoutWidth(width: string) {
    switch (width) {
      case "fluid":
        document.body.setAttribute("data-layout-size", "fluid");
        document.body.classList.remove("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "boxed":
        document.body.setAttribute("data-layout-size", "boxed");
        document.body.classList.add("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "scrollable":
        document.body.removeAttribute("data-layout-size");
        document.body.setAttribute("data-layout-scrollable", "true");
        document.body.setAttribute("data-layout-size", "fluid");
        document.body.classList.remove("right-bar-enabled", "vertical-collpsed");
      default:
        document.body.setAttribute("data-layout-size", "fluid");
        break;
    }
  }
}
