export const DEFAULT_LOGO_LIGHT = 'assets/images/multitenant-logo-light.png';
export const DEFAULT_LOGO_DARK = 'assets/images/multitenant-logo-dark.png';

export interface TenantInformation {
    id: number;
    name: string;
    logoDark: string;
    logoLight: string;
    bgImage: string;
    cognitoClientId: string;
    cognitoPoolId: string;
    cognitoRegion: string;
    configList?:string;
  };
  

  export interface TenantImage {
    id: number;
    image_name: string;
    is_login_image: boolean;
    photo: string;
    publish: boolean;
    quote: string;
  };
  