export enum UserType {
  acquirer = 'acquirer',
  merchant = 'merchant',
  transmitter= 'istransmitter',
}
  
  export class User {
    email: string;
    name: string;
    level: UserType;
    isAcquiringUser?: boolean;
    isMerchantUser?: boolean;
    istransmitter?:boolean;
    merchants?: merchant[]; 
    chosenMerchant?: merchant
  };
  export interface merchant {
    merchantId: string;
    name: string;
  }
  
  export interface Student {
    id: number;
    ask_eli: boolean;
    bio: string;
    birth_date: null;
    photo: null;
    campus: string;
    is_active: boolean;
    bookshelf: number;
    user: User
  }
  
  export interface UserCredentials {
    username: string;
    password: string;
  }
  