<div class="rightbar-title px-3 py-4">
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="mb-0">Elige hasta 4 columnas para mostrar</h5>
    </div>
    <hr>
  
    <div *ngFor="let item of items" class="form-check">
      <input type="checkbox" [id]="item.field" [(ngModel)]="item.checked" (change)="updateCheckboxStates()" [disabled]="item.disabled" class="custom-checkbox">
      <label [for]="item.field" class="custom-label">{{ item.name }}</label>
    </div>
  
    <div class="d-flex align-items-center gap-2 move-buttons">
      <button class="btn btn-primary"(click)="resetSelection()">Restablecer</button>
      <button class="btn btn-primary" (click)="applySelection()">Aplicar</button>
    </div>
  </div>
  