import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })
export class AlertsService {
  constructor() { }

  show(options: SweetAlertOptions): Promise<SweetAlertResult<any>> {
    return Swal.fire(options);
  }

  basic(titleText: string, options?: SweetAlertOptions): Promise<SweetAlertResult<any>> {
    return this.show({titleText, showConfirmButton: false, ...options});
  }
  iconAlert(titleText: string, iconHtml: string, text?: string): Promise<SweetAlertResult<any>> {
    return this.show({titleText, iconHtml, text, showConfirmButton: false});
  }

  success(titleText: string, text?: string, options?: SweetAlertOptions): Promise<SweetAlertResult<any>> {
    return this.toast(titleText, text, 'success', options);
  }

  error(titleText: string, text?: string, options?: SweetAlertOptions): Promise<SweetAlertResult<any>> {
    return this.toast(titleText, text, 'error', options);
  }

  info(titleText: string, text?: string, options?: SweetAlertOptions): Promise<SweetAlertResult<any>> {
    return this.toast(titleText, text, 'info', options);
  }

  warning(titleText: string, text?: string, options?: SweetAlertOptions): Promise<SweetAlertResult<any>> {
    return this.toast(titleText, text, 'warning', options);
  }

  toast(titleText: string, text?: string, icon?: SweetAlertIcon, options?: SweetAlertOptions): Promise<SweetAlertResult<any>> {
    options = options || {};
    options.timer = options.timer || 5000;
    options.position = options.position || 'top-end';
    return this.show({titleText, text, icon, toast: true, showConfirmButton: false, showCloseButton: true, ...options});
  }
  
  // Nuevo método para manejar errores HTTP
  handleHttpError(error: HttpErrorResponse): void {
    let titleText = 'Error';
    let text = 'Ocurrió un error inesperado';
    let icon: SweetAlertIcon = 'error';
    let timer = 4000;
    let showConfirmButton = false;

    switch (error.status) {
      case 400:
        titleText = 'Error 400';
        text = 'Solicitud incorrecta';
        break;
      case 401:
        titleText = 'Error 401';
        text = 'No autorizado';
        icon = 'warning';
        break;
      case 403:
        titleText = 'Error 403';
        text = 'Prohibido';
        icon = 'warning';
        break;
      case 404:
        titleText = 'Error 404';
        text = 'Recurso no encontrado';
        icon = 'info';
        break;
      case 500:
        titleText = '"¡Ups! Algo salió mal"';
        text = 'Por favor, intenta nuevamente en unos minutos.';
        break;
      default:
        titleText = `Error ${error.status}`;
        text = 'Error desconocido';
        break;
    }

    this.show({ titleText, text, icon, timer, showConfirmButton });
  }
}
