import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DetailsTransaction } from '@shared/models/modal-transaction.models';
import { LogSales } from '../models/transactions.modals';
import { TenantService } from '@shared/services/tenant.service';
import { AlertsService } from '@shared/services/alerts.service';


@Injectable({
  providedIn: 'root',
})
export class TransactionServices {

  private http = inject(HttpClient);
  private tenantService = inject(TenantService);
  private alerts = inject(AlertsService);

  detailsTrasactionApi(merchantId: string, saleId: string): Observable<DetailsTransaction | undefined> {
    const url = `${this.tenantService.api}/sale/${merchantId}/${saleId}`;
    return this.http.get<DetailsTransaction>(url).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener los detalles de la transacción');
        return throwError(() => new Error(error));
      })
    );
  }


  getLogSales(saleId: string): Observable<LogSales | undefined> {
    const url = `${this.tenantService.api}/sale/log/${saleId}`;
    return this.http.get<LogSales>(url).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener los log de ventas');
        return throwError(() => new Error(error));
      })
    );
  }

}