import { Injectable } from "@angular/core";
import { Papa } from 'ngx-papaparse';
import { UtilsService } from "./utils.service";
import { InputSearch } from "@shared/models/data-table.models";

export interface CsvMapping {
  field: string;
  header: string;
  required?: boolean;
};

export interface CsvError {
  row: number;
  description: string;
  field?: string;
  extraData?: any;
};

@Injectable({
  providedIn: 'root'
})
export class CsvService {
  private requiredFields: string[];
  private line: number;
  private mappings: { [key: string]: string };
  private fullData: any[] = [];
  private errors: CsvError[] = [];

  constructor(
    private papa: Papa,
    private utils: UtilsService
  ) { }

  downloadCsv(data: any[], filename: string, done: (event: MouseEvent) => void, columnDefs: any[], filterRangeDates?: any, search?: InputSearch): void {

    const headers = columnDefs.map(colDef => colDef.headerName || colDef.field);
    const fields = columnDefs.map(colDef => colDef.field);
    const formattedData = data.map(item => {
      const row = {};
      fields.forEach((field, index) => {
        row[headers[index]] = item[field];
      });
      return row;
    });
    const csv = this.papa.unparse({ fields: headers, data: formattedData });
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.addEventListener('click', (event: MouseEvent) => {
      setTimeout(() => done(event), 300);
    });
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${filename}.csv`);
    tempLink.click();
  }
  

  processCsv(file: File, mappings: CsvMapping[], done: (data: any[], error: any) => void) {
    this.line = 1;
    this.fullData = [];
    this.errors = [];
    this.requiredFields = mappings.filter(mapping => mapping.required).map(mapping => mapping.header);
    this.mappings = mappings.reduce((prev, mapping) => {
      prev[mapping.header] = mapping.field;
      return prev;
    }, {});

    // NOTE: Using any to prevent type error. Check https://github.com/alberthaff/ngx-papaparse/issues/54
    const config: any = {
      skipEmptyLines: true,
      header: true,
      chunkSize: 1024 * 5, // 100KB
      chunk: (chunk: { data: any[]; errors: any[]; meta: any; }, parser) => {
        chunk.data.forEach(row => {
          if (this.errors.length > 15) {
            this.errors = [{
              row: 0,
              description: 'Too many errors. Please check your CSV.'
            }];
            parser.abort();
          }
          this.processParsedData(row);
        });
      },
      complete: () => done(this.fullData, this.errors)
    };

    this.papa.parse(file, config);
  }

  private checkRequiredFields(row, missingFields: string[]) {
    return this.requiredFields.reduce((prev, field) => {
      if (!row[field]) {
        missingFields.push(field);
      }
      return prev && !!row[field];
    }, true);
  }

  private processParsedData(item: any) {
    const data = this.parseData(item);
    if (Array.isArray(data)) {
      this.errors.push({
        row: this.line,
        description: 'Missing required fields: ' + data.join(', ')
      });
    } else {
      this.fullData.push(data);
    }

    this.line += 1;
  }

  private parseData(item) {
    const missingFields = [];

    const complete = this.checkRequiredFields(item, missingFields);

    if (!complete) {
      return missingFields;
    }
    return Object.keys(item).reduce((prev, key) => {
      if (!this.utils.isNil(this.mappings[key])) {
        prev[this.mappings[key]] = item[key];
      }
      return prev;
    }, {});
  }
}
