import { Component, Input, OnInit, inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Paymentservices } from "src/app/tenant/payment/services/payment.services";
import { PaylinkDetails } from "@shared/models/paylink-details-models";
import { CurrencyService } from "@shared/services/currency.service";
import { CurrencyPipe } from "@angular/common";
import { STATUS_CLASS_MAP_PAYLINK, StatusPaylink } from "@shared/models/enum.models";


@Component({
  selector: "app-moda-detail-paylink",
  templateUrl: "./moda-detail-paylink.component.html",
  styleUrls: ["./moda-detail-paylink.component.scss"],
})
export class ModaDetailPaylinkComponent implements OnInit {
  @Input() merchantId!: string;
  @Input() orderId!: string;
  paylinkDetails!: PaylinkDetails;


  public activeModal = inject(NgbActiveModal);
  private paymentServices = inject(Paymentservices);
  private currencyService = inject(CurrencyService);
  private currencyPipe = inject(CurrencyPipe);

  ngOnInit(): void {
    this.getPaylinkDetails();
  }

  getPaylinkDetails() {
    this.paymentServices.getPaylinkDetails(this.merchantId, this.orderId).subscribe((details) => {
      this.paylinkDetails = details;
    });
  }

  public getStatusClass(status: StatusPaylink): string {
    return STATUS_CLASS_MAP_PAYLINK[status] || "badge-soft-primary";
  }

  public getStatusInSpanish(status: StatusPaylink): string {
    const statusMap: { [key in StatusPaylink]: string } = {
      [StatusPaylink.COMPLETED]: "Completado",
      [StatusPaylink.PENDING]: "Pendiente",
      [StatusPaylink.EXPIRED]: "Expirado",
      [StatusPaylink.VOIDED]: "Anulado",
    };
    return statusMap[status] || status;
  }

  getCurrencySymbol(currency: string): string {
    return this.currencyService.getCurrencySymbol(currency);
  }

  formatAmount(value: number, currency: string): string {
    if (currency === 'PYG') {
      return parseFloat(value.toString()).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return this.currencyPipe.transform(value?.toString(), '', '', '1.2-2');
  }
}



