import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TenantService } from '@shared/services/tenant.service';
import { AlertsService } from '@shared/services/alerts.service';
import { Product } from '../models/form.models';


@Injectable({
  providedIn: 'root',
})
export class ProductServices {

  private http = inject(HttpClient);
  private tenantService = inject(TenantService);
  private alerts = inject(AlertsService);

  createProducts(data: Product): Observable<Product> {
    let endpoint = `${this.tenantService.api}/product`;
    return this.http.post<Product>(endpoint, data).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al crear producto');
        return throwError(() => error);
      })
    );
  }

  idProduct(productId: string, merchantId: string): Observable<any | undefined> {
    const url = `${this.tenantService.api}/product/${productId}/${merchantId}`;
    return this.http.get<any>(url).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener el id del producto');
        return throwError(() => error);
      })
    );
  }

  updateProduct(data: Product): Observable<Product> {
    let endpoint = `${this.tenantService.api}/product`;
    return this.http.put<Product>(endpoint, data).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al actualizar producto');
        return throwError(() => error);
      })
    );
  }

  getAllProducts(merchantId: string): Observable<Product[]> {
    const url = `${this.tenantService.api}/product/${merchantId}`;
    return this.http.get<{ status: string, data: Product[] }>(url).pipe(
      map(response => response.data || []),
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener todos los productos');
        return throwError(() => error);
      })
    );
  }

  getListProducts(merchantId: string) {
    const url = `${this.tenantService.api}/product-rec/list/${merchantId}`;
    // Body POST
    const body = {
      limit: 10,
      page: 1,
      criteria: [],
      orderBy: {
        field: "createdOn",
        type: "desc"
      }
    };

    return this.http.post<{ list: Product[] }>(url, body).pipe(
      map(response => response.list),
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener la lista de productos');
        return throwError(() => error);
      })
    );
  }

  urlPresigned(merchantId: string, productId: string, fileName: string): Observable<any> {
    const url = `${this.tenantService.api}/merchant/presigned-url`;
    const body = {
      relativePath: `${merchantId}/${productId}/${fileName}`
    };
    return this.http.post<any>(url, body).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al subir archivo');
        return throwError(() => error);
      })
    )
  }

  urlDownload(merchantId: string, productId: string, fileName: string): Observable<any> {
    const url = `${this.tenantService.api}/merchant/download`;
    const body = {
      relativePath: `${merchantId}/${productId}/${fileName}`
    };
    return this.http.post<{ url: string }>(url, body).pipe(
      switchMap((response) => {
        const fileUrl = response.url;
        return this.http.get(fileUrl, { responseType: 'blob' });
      }),
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al descargar archivo');
        return throwError(() => error);
      })
    )
  }
}

