import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LayoutType } from '../layouts.model';
import { MenuItem } from '@shared/models/layout.models';


@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  homeLink: string | string[];
  profileLink: string | string[];
  menu$ = new BehaviorSubject<MenuItem[]>([]);
  userMenu$ = new BehaviorSubject<MenuItem[]>([]);
  userMenuDropDown$ = new BehaviorSubject<MenuItem[]>([]);
  $layoutType = new Subject<string>();


  get layoutType$(): Observable<string> {
    return this.$layoutType.asObservable();
  }

  constructor() {}

  toggleSidebar(): void {
    document.body.classList.toggle('right-bar-enabled');
  }

  setLayoutType(layoutType: LayoutType): void {
    this.$layoutType.next(layoutType);
  }

  setMenu(menu: MenuItem[]): void {
    this.menu$.next(menu);
  }
  
  setUserMenu(menu: MenuItem[]): void {
    this.userMenu$.next(menu);
  }


  setUserMenuDropDown(menu: MenuItem[]): void {
    this.userMenuDropDown$.next(menu);
  }
}
