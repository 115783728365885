import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';
import { JwtService } from './jwt.service';
import { LoginResult } from '@shared/models/auth.models';
import { User } from '@shared/models/user.models';
import { TenantService } from './tenant.service';
import { LoginResponse, LogoutAuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({ providedIn: 'root' })
export class AuthService {

  get currentUser(): User {
    return this.userService.currentUser;
  }

  get currentUser$(): Observable<User> {
    return this.userService.currentUser$;
  }

  private authState$ = new BehaviorSubject<boolean>(false);
  
  get isAuthenticated$(): Observable<boolean> {
    return this.authState$.asObservable();
  }

  get serverUrl(): string {
    return this.tenantService.api;
  }

  get logoutRedirect(): string[] {
    return ['/', this.tenantService.tenantName, 'login'];
  }

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private router: Router,
    private userService: UserService,
    private tenantService: TenantService,
    private oidcSecurityService: OidcSecurityService
  ) {
  }

  logout(redirect = true) {

    const logoutAuthOptions: LogoutAuthOptions = {
      customParams: {
        client_id: this.tenantService.tenantInformation.cognitoClientId,
        logout_uri: `${window.location.origin}/${this.tenantService.tenantName}/home`,
      }
    };
    this.oidcSecurityService
    .logoff('', logoutAuthOptions)
    .subscribe(() => {
      this.doLogoutUser();
      if (redirect) {
        this.router.navigate(this.logoutRedirect);
      }
    });
  }

  isAuthenticated(): boolean {
    return !!this.jwtService.getAccessToken();
  }

  private doLoginUser(loginResult: LoginResult) {
    this.jwtService.storeTokens(loginResult);
    this.authState$.next(true);
  }

  private doLogoutUser() {
    this.jwtService.removeTokens();
    this.userService.removeCurrentUser();
    this.authState$.next(false);
  }
  refreshToken(){
    return this.oidcSecurityService.getRefreshToken()
  }

  init(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.oidcSecurityService
      .checkAuth()
      .subscribe({
        next: (loginResponse: LoginResponse) => {
          if (loginResponse.isAuthenticated) {
            const { accessToken, idToken } = loginResponse;
            this.doLoginUser({ accessToken, idToken });
            this.userService.getUser()
            .subscribe({
              next: (user: User) => {
                this.userService.updateCurrentUser(user);
                resolve();
              },
              error: (err) => reject(err)
            });
          } else {
            resolve();
          }
        },
        error: (err) => reject(err)
      });
    });
  }
}
