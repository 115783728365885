import { Component, OnInit, OnDestroy } from '@angular/core';
import { InactivityService } from './shared/services/inactivity.service';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  
  constructor(
    private inactivityService: InactivityService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    // Only initialize inactivity tracking if user is authenticated
    this.authService.isAuthenticated$
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.inactivityService.init();
        }
      });
  }

  ngOnDestroy() {
    this.inactivityService.destroy();
  }
}
