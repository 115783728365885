import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { DateField, FieldBase, LazyDropdownField } from '../fields';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'elibrito-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss']
})
export class DynamicFormFieldComponent {
  @Input() field!: FieldBase<string>;
  @Input() form!: UntypedFormGroup;
  @Output() imageUploaded = new EventEmitter<Blob>();
  Editor = ClassicEditor;

  get dateField(): DateField {
    return this.field as DateField;
  }

  get lazyDropdownField(): LazyDropdownField {
    return this.field as LazyDropdownField;
  }

  get isValid() {
    const control = this.form.controls[this.field.key];
    return control.dirty && control.valid || control.pristine;
  }

  get fieldClasses(): {[key: string]: boolean} {
    return {
      'form-check form-switch form-switch-md': this.field.controlType.toLowerCase() === 'checkbox',
      'valid': this.isValid,
      ...this.field.fieldClasses
    };
  }

  get sizeClass(): string {
    if (!this.field.size || this.field.size === 'md') {
      return '';
    }
    return `form-control-${this.field.size}`;
  }

  constructor(public translate: TranslateService) {}

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const uploadedFile = event.target.files[0];
      const fileSrc = this.field.key + 'Source';
      const data = {
        [fileSrc]: uploadedFile
      };
      this.form.patchValue(data);
    }
  }

  cleanFilePath(filePath: string) {
    const paths = filePath.split('/');
    return paths[paths.length - 1];
  }

  hasError(error: string) {
    const control = this.form.controls[this.field.key];
    return control.errors && control.errors[error];
  }
}
