import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of} from "rxjs";
import { catchError, map, shareReplay, switchMap } from "rxjs/operators";
import { CatalogService } from "@shared/services/catalog.service";
import { JwtService } from "./jwt.service";
import { User, UserType } from "@shared/models/user.models";
import { jwtDecode } from "jwt-decode";
import Swal from 'sweetalert2';

export const DEFAULT_PROFILE_PICTURE = `assets/images/users/profile-placeholder.png`;

@Injectable({
  providedIn: "root",
})
export class UserService {
  private readonly CURRENT_USER_INFO = "CURRENT_USER";
  private $currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  currentUser$: Observable<User> = this.$currentUser.asObservable().pipe(shareReplay(1));

  get currentUser(): User {
    return this.$currentUser.value;
  }

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private catalog: CatalogService,
  ) {
    if (localStorage.getItem(this.CURRENT_USER_INFO)) {
      const user: User = JSON.parse(localStorage.getItem(this.CURRENT_USER_INFO));
      this.updateCurrentUser(user);
    } else if (this.jwtService.getAccessToken()) {
      this.currentUser$ = this.getUser();
    }
  }

  getUser(): Observable<User> {
    const userData = jwtDecode(this.jwtService.getIDToken()) as any;
    let level = UserType.merchant;
    if(userData['cognito:groups']){
      level = userData['cognito:groups'][0] === 'acquirer-admin' ? UserType.acquirer : UserType.merchant;
    }
    const user = {
      name: userData.name,
      email: userData.email,
      level: level,
      chosenMerchant: null,
      merchants: [],
    };
    return of(user).pipe(
      switchMap((res: User) => {
        this.updateCurrentUser(res);
        if (res.level === UserType.merchant) {
          return this.getUserMerchants(res);
        }
        return of(res); 
      }),
      catchError(error => {
        Swal.fire({
          position: 'top',
          icon: 'error',
          title: 'Error',
          text: 'Error fetching user',
          showConfirmButton: false,
          timer: 2500
        });
        return of(null);
      }),
      shareReplay(1)
    );
  }

  getUserMerchants(user:User): Observable<User> {
    return this.http.get<any>(`${this.catalog.serverApi}/merchant/access`).pipe(
      map((res) => {
        user.merchants = res;
        if(!user.chosenMerchant){
          user.chosenMerchant = user.merchants[0];
        }
        return user;
      }),
      shareReplay(1)
    );
  }

  updateCurrentUser(user: User): void {
    this.setUserTypeFlags(user);
    this.$currentUser.next(user);
    this.storeUser(user);
  }

  removeCurrentUser() {
    this.$currentUser.next(null);
    localStorage.removeItem(this.CURRENT_USER_INFO);
  }

  isType(userType: UserType) {
    return this.currentUser.level === userType;
  }

  private setUserTypeFlags(user: User) {
    switch (user.level) {
      case UserType.acquirer:
        user.isAcquiringUser = true;
        break;
      case UserType.merchant:
        user.isMerchantUser = true;
        break;
      case UserType.transmitter:
        user.istransmitter = true;
        break;
    }
  }

  private storeUser(userInfo: User) {
    if (localStorage.getItem(this.CURRENT_USER_INFO)){
      localStorage.removeItem(this.CURRENT_USER_INFO)
    }
    localStorage.setItem(this.CURRENT_USER_INFO, JSON.stringify(userInfo));
  }
}
