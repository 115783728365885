// src/app/services/currency.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
    private currencySymbols: { [key: string]: string } = {
      'PEN': 'S/',
      'USD': '$',
      'PYG': 'Gs.',
      'EUR': '€',
      'GBP': '£',
      'JPY': '¥',
      'CNY': '¥',
      'INR': '₹',
      'BRL': 'R$',
      'CAD': 'C$',
      'AUD': 'A$',
      'CHF': 'CHF',
      'MXN': '$',
      'ARS': '$',
      'CLP': '$',
      'COP': '$',
      'UYU': '$',
      'ZAR': 'R',
      'KRW': '₩',
      'SGD': 'S$',
      'HKD': 'HK$',
      'NZD': 'NZ$'
    };

  constructor() {}

  getCurrencySymbol(currencyCode: string): string {
    return this.currencySymbols[currencyCode] || '';
  }
}