import { Component, Input } from "@angular/core";
import { RightsidebarService } from "@layouts/services/rightsidebar.service";
import { FilterColumns } from "@shared/models/filters.models";


@Component({
  selector: "multitenat-columns",
  templateUrl: "./columns.component.html",
  styleUrl: "./columns.component.scss",
})
export class ColumnsComponent {
  @Input() items: FilterColumns[] =[];
  
  maxSelection: number = 4;

  constructor(private rightsidebarService: RightsidebarService) {}

  updateCheckboxStates() {
    const selectedItems = this.items.filter(item => item.checked).length;
    this.items.forEach(item => {
      item.disabled = !item.checked && selectedItems >= this.maxSelection;
    });
  }

  applySelection() {
    this.updateCheckboxStates();
    const selectedItems = this.items.filter((item) => item.checked);
    this.rightsidebarService.emitRightViewFilter({
      view: 'columns',
      filters: selectedItems,
    });
  }

  resetSelection() {
    this.items.forEach((item) => {
      item.checked = false;
      item.disabled = false;
    });
    this.rightsidebarService.emitRightViewFilter({
      view: 'columns',
      filters: [],
    });
  }
}
