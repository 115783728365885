import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'multitenant-link-cell-renderer',
  template: `
    <ng-container *ngIf="params.node.data">
      <a *ngIf="params.link" [routerLink]="routerLink" class="btn btn-primary w-sm" [title]="params.label">
        <i [class]="params.iconClass"></i>
      </a>
      <a *ngIf="params.externalLink" [href]="params.externalLink" class="btn btn-primary w-sm" [title]="params.label">
        <i [class]="params.iconClass"></i>
      </a>
    </ng-container>
  `
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {

  params: any;

  get routerLink(): string | string[] {
    if (this.params.link && Array.isArray(this.params.link)) {
      const link = [...this.params.link];
      if (this.params.node.data) {
        link.push(this.params.node.data.id);
      }
      return link;
    }

    const id = this.params.node.data ? `/${this.params.node.data.id}` : '';
    return this.params.link + id;
  };

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
