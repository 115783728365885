<ng-select
  [items]="options"
  *ngIf="!loading"
  #select
  [bindLabel]="displayKey"
  dropdownPosition="auto"
  [bindValue]="idKey"
  [labelForId]="displayKey"
  placeholder="Select album"
  [multiple]="true"
  [formControlName]="formControlName"
>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <div>
      <span></span>
      <span>{{ item[displayKey] }}</span>
    </div>
  </ng-template>
</ng-select>