import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'multitenant-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})

/**
 * Page 404 component
 */
export class Page404Component implements OnInit {
  next = '';

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.next = this.route.snapshot.queryParamMap.get('next');
  }
}
