<multitenant-base-modal>
    <div
      class="modal-title d-flex align-items-center mb-2 justify-content-center">
      <i class="bx bx-cart-alt text-success small-icon"></i>
      <h4 class="ms-2 mb-0 custom-button">Detalle del Producto</h4>
    </div>
  
    <div class="body-container">
      <div class="tab-content" id="nav-tabContent"
        style="position: relative;top: 10px;">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel"
          aria-labelledby="nav-home-tab">
          <div class="card colors-cards">
            <p class="tab-text-commerm">Datos del Producto</p>
            <div class="card-body">
              <div class="btn-container">
                <label class="text-commerm">Código del Producto:</label>
                <label
                  class="text-rest">{{listProducts?.productId}}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Nombre del Producto:</label>
                <label class="text-ruc">{{listProducts?.name}}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Descripción del Producto:</label>
                <label class="text-ruc">{{listProducts?.description}}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Estado:</label>
                <span [ngClass]="[getStatusClass(listProducts?.status),'no-background']">
                  <label class="text-center-status">{{getStatusInSpanish(listProducts?.status)}}</label> </span>
                
              </div>
             
            </div>
          </div>
       
        </div>
      </div>
    </div>
    
    <div class="modal-footer justify-content-center">
      <button type="button" class="btn btn-outline-secondary"
        (click)="activeModal.close('Close click')">Cerrar</button>
     
        
    </div>
  
  </multitenant-base-modal>
