import { MenuItem } from "@shared/models/layout.models";


// export layout related constants
export const LAYOUT_WIDTH = 'fluid';

export const SIDEBAR_TYPE = 'dark';

export const TOPBAR = 'dark';

export interface ILayoutConfig {
  menu: MenuItem[];
};

export enum LayoutType {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
  CUSTOM = 'custom',
};
