import { Observable } from "rxjs";

export interface FieldsGroup {
  title?: string;
  fields: Array<FieldBase<string> | {fields: FieldBase<string>[]}>;
}

export interface FieldBaseOptions<T> {
  value?: T;
  key?: string;
  label?: string;
  required?: boolean;
  order?: number;
  type?: string;
  fieldClasses?: { [key: string]: string };
  helpText?: string;
  width?: number;
  classes?: string;
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  min?: number;
  max?: number;
  maxLength?: number;
  minLength?: number;
  noLabel?: boolean;
  options?: { key: string; value: string }[];
  size?: string;
}

// This constant is use to unify all the field types
// this is meant to be use as a return type using `typeof FieldTypes`
export const FieldTypes:
  InputField |
  TextareaField |
  DropdownField |
  LazyDropdownField |
  DateField |
  FileField |
  CheckboxField |
  RadioField |
  RichTextField = null;

export class FieldBase<T> {
  value: T | undefined;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  fieldClasses: { [key: string]: string } | {};
  helpText: string;
  width: number;
  hidden: boolean;
  readonly: boolean;
  disabled: boolean;
  placeholder: string;
  min?: number;
  max?: number;
  maxLength?: number;
  minLength?: number;
  noLabel?: boolean;
  size?: string;
  options: { key: string; value: string }[];
  classes:string;

  constructor(fieldOptions: FieldBaseOptions<T> = {}) {
    this.value = fieldOptions.value;
    this.key = fieldOptions.key ?? '';
    this.label = fieldOptions.label ?? '';
    this.required = !!fieldOptions.required;
    this.order = fieldOptions.order === undefined ? 1 : fieldOptions.order;
    this.type = fieldOptions.type ?? 'text';
    this.fieldClasses = fieldOptions.fieldClasses ?? {};
    this.helpText = fieldOptions.helpText ?? '';
    this.width = fieldOptions.width ?? 6;
    this.placeholder = fieldOptions.placeholder ?? '';
    this.min = fieldOptions.min;
    this.max = fieldOptions.max;
    this.maxLength = fieldOptions.maxLength;
    this.minLength = fieldOptions.minLength;
    this.readonly = fieldOptions.readonly ?? false;
    this.disabled = fieldOptions.disabled ?? false;
    this.noLabel = fieldOptions.noLabel ?? false;
    this.options = fieldOptions.options ?? [];
    this.classes = fieldOptions.classes ?? '';
    this.size = fieldOptions.size ?? 'lg';
  }
}

export class InputField extends FieldBase<string> {
  override controlType = 'input';
  // Input element, the type of the input will be set by the type property in the fieldOptions object
}

export class TextareaField extends FieldBase<string> {
  override controlType = 'textarea';
}

export class DropdownField extends FieldBase<string> {
  override controlType = 'dropdown';
}

export interface LazyDropdownFieldOptions<T> extends FieldBaseOptions<T> {
  endpoint?: string;
  loadSelectedElement?: (ids: any) => Observable<any[]>;
  map?: (data: any, index?: number) => any;
  pageQueryparam?: string;
  pageSizeQueryparam?: string;
  debounceTime?: number;
  searchKey?: string;
  optionKey?: string;
  displayKey?: string;
  idKey?: string;
  placeholder?: string;
  imgKey?: string;
  multiple?: boolean;
}

export class LazyDropdownField extends FieldBase<string> {
  override controlType = 'lazy-dropdown';
  endpoint: string;
  loadSelectedElement: (ids: any) => Observable<any[]>;
  pageQueryparam: string;
  pageSizeQueryparam: string;
  debounceTime: number;
  searchKey: string;
  optionKey: string;
  displayKey: string;
  idKey: string;
  placeholder: string;
  imgKey: string;
  multiple: boolean;
  map: (data: any, index?: number) => any;
  constructor(fieldOptions: LazyDropdownFieldOptions<string> = {}) {
    super(fieldOptions);
    this.endpoint = fieldOptions.endpoint ?? '';
    this.loadSelectedElement = fieldOptions.loadSelectedElement ?? (() => new Observable<any[]>());
    this.pageQueryparam = fieldOptions.pageQueryparam ?? '';
    this.pageSizeQueryparam = fieldOptions.pageSizeQueryparam ?? '';
    this.debounceTime = fieldOptions.debounceTime ?? 300;
    this.searchKey = fieldOptions.searchKey ?? '';
    this.optionKey = fieldOptions.optionKey ?? '';
    this.displayKey = fieldOptions.displayKey ?? '';
    this.idKey = fieldOptions.idKey ?? '';
    this.placeholder = fieldOptions.placeholder ?? '';
    this.imgKey = fieldOptions.imgKey ?? '';
    this.multiple = fieldOptions.multiple ?? false;
    this.map = fieldOptions.map ?? ((data: any) => data);
  }
}

export interface DateFieldOptions<T> extends FieldBaseOptions<T> {
  minDate?: Date;
}
export class DateField extends FieldBase<string> {
  override controlType = 'date';
  minDate?: Date;

  dateFormatString(currentLang: string): string {
    switch (currentLang) {
      case 'en':
        return 'MM/DD/YYYY';
      case 'es':
      case 'pt':
        return 'DD/MM/YYYY';
      default:
        return '';
    }
  }

  constructor(fieldOptions: DateFieldOptions<string> = {}) {
    super(fieldOptions);
    this.minDate = fieldOptions.minDate;
  }
}

export interface FileFieldOptions<T> extends FieldBaseOptions<T> {
  accept?: string;
  defaultImage?: string;
  imageStyle?: 'circle' | 'square';
  viewFileName?: boolean;
}

export class FileField extends FieldBase<string> {
  override controlType = 'file';
  accept: string;
  defaultImage: string;
  imageStyle: 'circle' | 'square';
  viewFileName: boolean;

  constructor(fieldOptions: FileFieldOptions<string> = {}) {
    super(fieldOptions);
    this.imageStyle = fieldOptions.imageStyle;
    this.defaultImage = fieldOptions.defaultImage ?? null;
    this.accept = fieldOptions.accept ?? '';
    this.viewFileName = fieldOptions.viewFileName ?? true;
  }
}

export class CheckboxField extends FieldBase<string> {
  override controlType = 'checkbox';
}

export class RadioField extends FieldBase<string> {
  override controlType = 'radio';
}

export class RichTextField extends FieldBase<string> {
  override controlType = 'richText';
}
