<div id="layout-wrapper">

  <multitenant-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
  </multitenant-topbar>

  <multitenant-sidebar [menuItems]="menuItems$ | async" [isCondensed]="isCondensed"></multitenant-sidebar>
  <multitenant-rightsidebar></multitenant-rightsidebar>

  <div class="main-content">
    <div class="page-content">
      <!-- content -->
      <ng-content></ng-content>
    </div>

    <!-- footer -->
    <multitenant-footer></multitenant-footer>
  </div>
</div>

