import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MenuSidebar } from '@shared/models/menu.models';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { CatalogService } from './catalog.service';
import { MenuItem } from '@shared/models/layout.models';


@Injectable({
    providedIn: 'root'
})
export class MenuService {

        private httpClient = inject(HttpClient);
        private catalog = inject(CatalogService);

    //Método para transformar MenuSidebar a MenuItem
    private transformSidebarToMenuItem(sidebarItem: MenuSidebar, root: string[], parentLabel: string | null = null): MenuItem {
        return {
            id: sidebarItem.order,
            label: sidebarItem.label,
            icon: sidebarItem.icon,
            link: sidebarItem.route ? [...root, ...sidebarItem.route.split('/').filter(segment => segment)].join('/').replace(/^\/+/, '/') : undefined,
            isCollapsed: false,
            parent: parentLabel, // Asigna el label del menú padre si existe
            subItems: sidebarItem.children?.map(child => this.transformSidebarToMenuItem(child, root, sidebarItem.label)),
        };
    }
         
    // Método para agregar una raíz al menú
    private addRoot(root: string[], menu: MenuSidebar[]): MenuItem[] {
        return menu.map((item: MenuSidebar) => {
          return this.transformSidebarToMenuItem(item, root);
        });
      }

    postMenuSidebar(): Observable<MenuSidebar[]> {
        const url = `${this.catalog.serverApi}/menu`;
        return this.httpClient.post<MenuSidebar[]>(url, {}).pipe(
            catchError(error => {
                console.error('Error al obtener el menú', error);
                return throwError(() => error);
            })
        );
    }
        
    getUserMenu(tenant: string): Observable<MenuItem[]> {
        const libraryRoot = ['/', tenant];
        return this.getMenuByRole().pipe(
            map(response => this.addRoot(libraryRoot, this.sortMenuItemsByOrder(response)))
        );
    }
    
    getMenuByRole(): Observable<MenuSidebar[]> {
        return this.postMenuSidebar();
    }

    sortMenuItemsByOrder(menu: MenuSidebar[]): MenuSidebar[] {
        return menu
          .map(item => ({
            ...item,
            // Ordenar los submenús si existen
            children: item.children ? this.sortMenuItemsByOrder(item.children) : [],
          }))
          // Ordenar por el campo 'order'
          .sort((a, b) => a.order - b.order);
      }
    
    }
