import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { MerchantDetails, Parameters, SubProductDetails } from "@shared/models/merchants.models";
import { UserService } from "@shared/services/user.service";
import { CreateMerchantResponse, MerchantCbo, Status } from "../models/new-merchant.models";
import { GroupDetails, ProductDetails } from "@shared/models/merchants.models";
import { AlertsService } from "@shared/services/alerts.service";
import { TenantService } from "@shared/services/tenant.service";


@Injectable({
  providedIn: "root",
})
export class MerchantsServices {

  private http = inject(HttpClient);
  private tenantService = inject(TenantService);
  private userService = inject(UserService);
  private alerts = inject(AlertsService);

  newMerchant(data: CreateMerchantResponse): Observable<CreateMerchantResponse> {
    const user = this.userService.currentUser;
    let endpoint = `${this.tenantService.api}/merchant`;
    if (user.isMerchantAdmin) {
      endpoint = `${endpoint}/${user.chosenMerchant.merchantId}`;
    }
    return this.http.post<CreateMerchantResponse>(endpoint, data).pipe(
      catchError((error) => {
        this.alerts.error(`Error: ${error.status}`, 'Error al crear el merchant');
        return throwError(() => new Error(error));
      })
    );
  }

  editMerchant(data: CreateMerchantResponse): Observable<CreateMerchantResponse> {
    const user = this.userService.currentUser;
    let endpoint = `${this.tenantService.api}/merchant`;
    if (user.isMerchantAdmin) {
      endpoint = `${endpoint}/${user.chosenMerchant.merchantId}`;
    }
    return this.http.patch<CreateMerchantResponse>(endpoint, data).pipe(
      catchError((error) => {
        this.alerts.error(`Error: ${error.status}`, 'Error al editar el merchant');
        return throwError(() => new Error(error));
      })
    );
  }

  getGroups(): Observable<GroupDetails[] | undefined> {
    const url = `${this.tenantService.api}/group/cbo?query=`;
    return this.http.get<GroupDetails[]>(url).pipe(
      catchError((error) => {
        this.alerts.error(`Error: ${error.status}`, 'Error con la lista');
        return throwError(() => new Error(error));
      })
    );
  }

  getProduct(): Observable<ProductDetails[] | undefined> {
    const url = `${this.tenantService.api}/product/cbo?query=`;
    return this.http.get<ProductDetails[]>(url).pipe(
      catchError((error) => {
        this.alerts.error(`Error: ${error.status}`, 'Error con el producto');
        return throwError(() => new Error(error));
      }),
    );
  }

  getSubProduct(productId: string): Observable<SubProductDetails[] | undefined> {
    const url = `${this.tenantService.api}/subproduct/${productId}/cbo?query=`;
    return this.http.get<SubProductDetails[]>(url).pipe(
      catchError((error) => {
        this.alerts.error(`Error: ${error.status}`, 'Error con el sub producto');
        return throwError(() => new Error(error));
      }),
    );
  }

  getParameters(productId: string, subProductId: string): Observable<Parameters[] | undefined> {
    const url = `${this.tenantService.api}/subproduct/${productId}/${subProductId}`;
    return this.http.get<Parameters[]>(url).pipe(
      catchError((error) => {
        this.alerts.error(`Error: ${error.status}`, 'Error con los parametros del producto');
        return throwError(() => new Error(error));
      })
    );
  }

  idmerchant(merchantId: string): Observable<CreateMerchantResponse | undefined> {
    const url = `${this.tenantService.api}/merchant/${merchantId}`;
    return this.http.get<CreateMerchantResponse>(url).pipe(
      catchError((error) => {
        this.alerts.error(`Error: ${error.status}`, 'Error con el Id del merchant');
        return throwError(() => new Error(error));
      })
    );
  }

  detailsMerchantApi(merchantId: string): Observable<MerchantDetails | undefined> {
    const url = `${this.tenantService.api}/merchant/${merchantId}`;
    return this.http.get<MerchantDetails>(url).pipe(
      catchError((error) => {
        this.alerts.error(`Error: ${error.status}`, 'Error con los deatelles del merchant');
        return throwError(() => new Error(error));
      })
    );
  }

  updateStatus(merchantId: string, active: boolean): Observable<Status> {
    const user = this.userService.currentUser;
    let endpoint = `${this.tenantService.api}/merchant/status`;
    if (user.isMerchantAdmin) {
      endpoint = `${endpoint}/${user.chosenMerchant.merchantId}`;
    }
    return this.http.patch<Status>(endpoint, { merchantId, active }).pipe(
      catchError((error) => {
        this.alerts.error(`Error: ${error.status}`, 'Error con actualizar');
        return throwError(() => new Error(error));
      })
    );
  }

  getCboMerchant(query: string = ''): Observable<MerchantCbo[] | undefined> {
    const url = `${this.tenantService.api}/merchant/cbo?query=${query}`;
    return this.http.get<MerchantCbo[]>(url).pipe(
      catchError((error) => {
        this.alerts.error(`Error: ${error.status}`, 'Error con el cbo del merchant');
        return throwError(() => new Error(error));
      })
    );
  }

  urlPresigned(merchantId: string, fileName: string): Observable<any> {
    const url = `${this.tenantService.api}/merchant/presigned-url`;
    const body = {
      relativePath: `${merchantId}/${fileName}`
    };
    return this.http.post<any>(url, body).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al subir archivo');
        return throwError(() => error);
      })
    )
  }

  urlDownload(merchantId: string, fileName: string): Observable<any> {
    const url = `${this.tenantService.api}/merchant/download`;
    const body = {
      relativePath: `${merchantId}/${fileName}`
    };
    return this.http.post<{ url: string }>(url, body).pipe(
      switchMap((response) => {
        const fileUrl = response.url;
        return this.http.get(fileUrl, { responseType: 'blob' });
      }),
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al descargar archivo');
        return throwError(() => error);
      })
    )
  }

}