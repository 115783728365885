import { Injectable, NgZone } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private timeoutId: any;
  private readonly TIMEOUT_IN_MS = 10 * 60 * 1000; // 10 minutes in milliseconds

  constructor(
    private authService: AuthService,
    private ngZone: NgZone
  ) {}

  /**
   * Initialize the inactivity tracking
   */
  init(): void {
    // Setup event listeners for user activity
    const events = ['click', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    
    events.forEach(event => {
      document.addEventListener(event, () => this.resetTimer());
    });

    // Start the initial timer
    this.resetTimer();
  }

  /**
   * Reset the inactivity timer
   */
  private resetTimer(): void {
    // Clear the existing timeout
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    // Set a new timeout
    this.ngZone.runOutsideAngular(() => {
      this.timeoutId = setTimeout(() => {
        // Run logout inside NgZone to ensure proper Angular lifecycle
        this.ngZone.run(() => {
          if (this.authService.isAuthenticated()) {
            console.log('Session expired due to inactivity');
            this.authService.logout();
          }
        });
      }, this.TIMEOUT_IN_MS);
    });
  }

  /**
   * Clean up event listeners and timer
   */
  destroy(): void {
    const events = ['click', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    events.forEach(event => {
      document.removeEventListener(event, () => this.resetTimer());
    });

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
