
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpContextToken,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { AuthService } from "@shared/services/auth.service";
import { JwtService } from "@shared/services/jwt.service";
import { TenantService } from "@shared/services/tenant.service";

export const BYPASS_LOG = new HttpContextToken(() => false);
export const INCLUDE_CONTENT_TYPE = new HttpContextToken(() => true);

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authService: AuthService,
    private jwtService: JwtService,
    private tenantService: TenantService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.context.get(BYPASS_LOG) === true) return next.handle(request);

    if (this.jwtService.getAccessToken() && request.url.includes(this.tenantService.api)) {
      request = this.addToken(request, this.jwtService.getAccessToken());
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (
          request.url.includes("token/refresh/") &&
          error instanceof HttpErrorResponse &&
          error.status === 401
        ) {
          this.authService.logout();
          return throwError(() => error);
        } else if (error instanceof HttpErrorResponse && error.status === 401) {
          // Ignore login page 401 errors
          if (request.url.includes(this.tenantService.api) && !document.URL.includes('/login')) {
            return this.handle401Error(request, next);
          }
          return throwError(() => error);
        } else if (error instanceof HttpErrorResponse && error.status === 403) {
          // Handle 403 errors
          // Add your code here to handle the 403 error
          // For example, you can redirect the user to a forbidden page or display an error message
          // You can also throw an error to be caught by the caller of the HTTP request
          // return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access);
          return next.handle(this.addToken(request, token.access));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.authService.logout();
          return throwError(() => err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    const setHeaders = {
      Authorization: `Bearer ${token}`,
    };

    if(request.context.get(INCLUDE_CONTENT_TYPE)) {
      setHeaders["Content-Type"] = "application/json";
    }

    return request.clone({ setHeaders });
  }
}
