import { Component, Input, OnInit } from '@angular/core';
import { RightsidebarService } from '@layouts/services/rightsidebar.service';
import { FilterCategory, FilterCategoryOption } from '@shared/models/filters.models';

@Component({
  selector: 'multitenant-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent {
  @Input() filterCategories: FilterCategory[] = [];

  constructor(
    private rightsidebarService: RightsidebarService
  ) {}

  toggleSelection(option: FilterCategoryOption):void {
     option.selected = !option.selected;
  }


  resetFilters():void {
    this.filterCategories.forEach(category => {
      category.options.forEach(option => option.selected = false);
    });
  }

  applyFilters(): void {
    const selectedCategories = this.filterCategories.filter(category => category.options.some(option => option.selected));
    if (selectedCategories.length) {
      const filterCategories = selectedCategories.map(category => {
        const values = category.options.filter(option => option.selected).map(option => option.value);
        return { field: category.field, value: values };
      });
      this.rightsidebarService.emitRightViewFilter({
        view: 'filters',
        filters: filterCategories
      });
    }
  }
  removeFilter(){
    this.filterCategories.forEach(category => {
      category.options.forEach(option => option.selected = false);
    });
    this.rightsidebarService.emitRightViewFilter({
      view: 'filters',
      filters: []
    });
  }
}
