<div class="rightbar-title px-3 py-4">
  <div class="d-flex justify-content-between align-items-center">
    <h5 class="mb-0">Filtrar por</h5>
  </div>
  <hr>


  <div ngbAccordion>
    @for (filter of filterCategories; track $index) {
    <div ngbAccordionItem [collapsed]="filter !== filter?.name.name">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{ filter.name }}</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <!-- @if(filter?.category !== 'Montos'){ -->
            @for ( option of filter?.options; track $index) {
            <div class="style-badge" [class.selected]="option.selected" (click)="toggleSelection(option)">
              <i class="bx" [class.bx-circle]="!option.selected" [class.bx-check]="option.selected"></i>
              {{ option.name }}
            </div>
            }

            <!-- } -->
            <!-- @if(filter?.category == 'Montos'){
            <div class="d-flex align-items-center gap-2">
              <select class="form-select">
                <option selected>Rango</option>
                @for ( option of filter?.options; track $index) {
                <option [value]="option.name">{{ option.name }}</option>
                }
              </select>

              <input type="text" class="form-control" id="decimal" placeholder="00.00">

            </div>
            } -->
          </ng-template>
        </div>
      </div>
    </div>
    }
  </div>


  <!-- Footer con botones -->
  <div class="d-flex align-items-center gap-2 move-buttons">
    <button class="btn btn-primary" (click)="removeFilter()">Eliminar filtro</button>
    <button type="button" class="btn btn-primary" (click)="applyFilters()">Aplicar</button>
  </div>
</div>