import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, map, shareReplay, switchMap } from "rxjs/operators";
import { JwtService } from "./jwt.service";
import { User, UserType } from "@shared/models/user.models";

import { TenantService } from "./tenant.service";
import { AlertsService } from "./alerts.service";

export const DEFAULT_PROFILE_PICTURE = `assets/images/users/profile-placeholder.png`;

@Injectable({
  providedIn: "root",
})
export class UserService {
  private readonly CURRENT_USER_INFO = "CURRENT_USER";
  private $currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  currentUser$: Observable<User> = this.$currentUser.asObservable().pipe(shareReplay(1));

  get currentUser(): User {
    return this.$currentUser.value;
  }

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private tenantService: TenantService,
    private alerts: AlertsService
  ) {
  }

  getUser(): Observable<User> {
    return this.http.get<any>(`${this.tenantService.api}/user/info`).pipe(
      map(response => {
        return response.responseData as User
      }),
      switchMap((res: User) => {

        this.updateCurrentUser(res);
        if (res.role === UserType.MERCHANT_ADMIN || res.role === UserType.MERCHANT_OPERATOR) {
          return this.getUserMerchants(res);
        }
        return of(res);
      }),
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener el usuario');
        return of(null);
      }),
      shareReplay(1)
    );
  }

  getUserMerchants(user: User): Observable<User> {
    return this.http.get<any>(`${this.tenantService.api}/merchant/access`).pipe(
      map((res) => {
        user.merchants = res;
        if (!user.chosenMerchant) {
          user.chosenMerchant = user.merchants[0];
        }
        return user;
      }),
      shareReplay(1)
    );
  }

  updateCurrentUser(user: User): void {
    this.setUserTypeFlags(user);
    this.$currentUser.next(user);
    this.storeUser(user);
  }

  removeCurrentUser() {
    this.$currentUser.next(null);
    localStorage.removeItem(this.CURRENT_USER_INFO);
  }

  isType(userType: UserType) {
    return this.currentUser.role === userType;
  }

  private setUserTypeFlags(user: User) {
    switch (user.role) {
      case UserType.ACQUIRER_ADMIN:
        user.isAcquirerAdmin = true;
        break;
      case UserType.ACQUIRER_OPERATOR:
        user.isAcquirerOperator = true;
        break;
      case UserType.MERCHANT_ADMIN:
        user.isMerchantAdmin = true;
        break;
      case UserType.MERCHANT_OPERATOR:
        user.isMerchantOperator = true;
        break;

    }
  }

  private storeUser(userInfo: User) {
    if (localStorage.getItem(this.CURRENT_USER_INFO)) {
      localStorage.removeItem(this.CURRENT_USER_INFO)
    }
    localStorage.setItem(this.CURRENT_USER_INFO, JSON.stringify(userInfo));
  }

  getRoles(): Observable<string[]> {
    const url = `${this.tenantService.api}/user/role/cbo`;

    return this.http.get<{ roles: string[] }>(url).pipe(
      map(response => response.roles),
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener la lista de roles');
        return throwError(() => new Error(error));
      })
    );
  }

  getUserById(userId: string): Observable<User> {
    const url = `${this.tenantService.api}/user/${userId}`;

    return this.http.get<User>(url).pipe(
      map((response: any) => {
        return response.responseData;
      }),
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener el usuario');
        return throwError(() => new Error(error));
      })
    );
  }

  updateUser(userId: string, data: any): Observable<any> {
    const url = `${this.tenantService.api}/user/${userId}`;
    return this.http.patch<any>(url, data).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al actualizar el usuario');
        return throwError(() => new Error(error));
      })
    );
  }

}
