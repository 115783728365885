import { Component, inject, Input, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyService } from '@shared/services/currency.service';
import { SubscriptionService } from 'src/app/tenant/subscription/services/subscription.service';
import { Subscription } from 'src/app/tenant/subscription/models/form.models';
import { GeneralStatus, STATUS_CLASS_MAP, STATUS_TRANSLATIONS } from '@shared/models/enum-status.models';


@Component({
  selector: 'app-modal-subcription',
  templateUrl: './modal-subcription.component.html',
  styleUrls: ['./modal-subcription.component.scss']
})
export class ModalSubcriptionComponent implements OnInit {

  @Input() subscriptionId!: string;
  listSus!: Subscription;


  private subscriptionService = inject(SubscriptionService);
  private currencyService = inject(CurrencyService);
  public activeModal = inject(NgbActiveModal);
  private currencyPipe = inject(CurrencyPipe);


  ngOnInit(): void {
    this.getDetailsSus();
  }

  getDetailsSus(): void {
    if (!this.subscriptionId) {
      console.error('subscriptionId is not defined');
      return;
    }

    this.subscriptionService.idSuscription(this.subscriptionId).subscribe(response => {
      this.listSus = response;
    }, error => {
      console.error('Error fetching subscription details:', error);
    });
  }

  public getStatusClass(status: GeneralStatus): string {
    return STATUS_CLASS_MAP[status] || "badge-soft-primary";
  }

  public getStatusInSpanish(status: GeneralStatus): string {
    return STATUS_TRANSLATIONS[status] || "Desconocido";
  }

  getCurrencySymbol(currencyCode: string): string {
    return this.currencyService.getCurrencySymbol(currencyCode);
  }

  formatAmount(value: number, currency: string): string {
    if (currency === 'PYG') {
      return parseFloat(value.toString()).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return this.currencyPipe.transform(value?.toString(), '', '', '1.2-2');
  }



}