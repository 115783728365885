import { Injectable } from '@angular/core';
// import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  listLang = [
    { text: 'English', flag: 'assets/images/flags/en.png', lang: 'en' },
    { text: 'Español', flag: 'assets/images/flags/es.png', lang: 'es' },
  ];

  get languages(): string[] {
    return this.listLang.map(x => x.lang);
  }

  constructor(
    public translate: TranslateService, 
    private cookieService: CookieService,
    // private adapter: DateAdapter<any>
) {
  }

  init() {
    this.translate.addLangs(this.listLang.map(lang => lang.lang));
    let browserLang = this.cookieService.get('lang') || this.translate.getBrowserLang();
    browserLang = this.listLang.find(lang => lang.lang === browserLang) ? browserLang : 'es';
    this.setLanguage(browserLang);
  }

  setLanguage(lang: string) {
    this.translate.use(lang);
    this.cookieService.set('lang', lang);
    // this.adapter.setLocale(lang);
  }


}
