

<div class="modal-header">
  <h4 *ngIf="data?.title; else modalTitle;" class="modal-title">{{data?.title}}</h4>
  <ng-template #modalTitle>
    <ng-content select=".modal-title"></ng-content>
  </ng-template>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClose(false)"></button>
</div>

<div class="modal-body">
  <div #ref><ng-content></ng-content></div>
  <ng-container *ngIf="!ref.hasChildNodes()">
    {{data?.body}}
  </ng-container>
</div>
<div *ngIf="data?.submitLabel && data?.cancelLabel; else modalFooter;" class="modal-footer">
  <button *ngIf="data?.cancelLabel" type="button" class="btn btn-light" (click)="onClose(false)">{{data?.cancelLabel || 'Cancel'}}</button>
  <button *ngIf="data?.submitLabel" type="button" class="btn btn-primary" (click)="onClose(true)">{{data?.submitLabel || 'Submit'}}</button>
</div>
<ng-template #modalFooter>
  <ng-content select=".modal-footer"></ng-content>
</ng-template>
<div class="spinner-wrapper" *ngIf="loading">
  <div class="spinner-chase">
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
    <div class="chase-dot"></div>
  </div>
</div>
