import { Component, Input, OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MerchantDetails } from '@shared/models/merchants.models';
import { MerchantsServices } from 'src/app/tenant/merchants/services/merchants.service';

@Component({
  selector: 'app-modal-detail-merchants',
  templateUrl: './modal-detail-merchants.component.html',
  styleUrl: './modal-detail-merchants.component.scss'
})
export class ModalDetailMerchantsComponent implements OnInit {
  @Input() merchantId!: string;
  merchantDetails!: MerchantDetails;

  public activeModal = inject(NgbActiveModal);
  private merchantsServices = inject(MerchantsServices);

  ngOnInit(): void {
    this.getMerchanDetails();
  }

  getMerchanDetails(): void {
    this.merchantsServices.detailsMerchantApi(this.merchantId).subscribe((merchant) => {
    this.merchantDetails = merchant
    });
  }


}
