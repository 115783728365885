<multitenant-base-modal>
  <!-- Modal Title -->
  <div
    class="modal-title d-flex align-items-center mb-2 justify-content-center">
    <i class="fas fa-link text-success small-icon"></i>
    <h4 class="ms-2 mb-0 custom-button">Detalle del Pago Link</h4>
  </div>

  <!-- Modal Body -->
  <div class="body-container">
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-home" role="tabpanel"
        aria-labelledby="nav-home-tab">

        <!-- Purchase Details Card -->
        <div class="card colors-cards">
          <p class="tab-text-commerm">Datos de la Compra</p>
          <div class="card-body">
            <div class="btn-container">
              <label class="text-commerm">Descripción:</label>
              <label class="text-rest">{{paylinkDetails?.description}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Monto:</label>
              <label class="text-amount">
                {{ getCurrencySymbol(paylinkDetails?.currencyType) }} {{ formatAmount(paylinkDetails?.amount, paylinkDetails?.currencyType) }}
                
              </label>
            </div>

            <div class="btn-container">
              <label class="text-commerm">Fecha de Creación:</label>
              <label class="text-addres">{{paylinkDetails?.createdOn | date:
                'dd/MM/yyyy '}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Número de Orden:</label>
              <label class="text-email">{{paylinkDetails?.orderId}}</label>
            </div>
          </div>
        </div>

        <!-- Link Details Card -->
        <div class="card colors-cards">
          <p class="tab-text-commerm">Datos del Link</p>
          <div class="card-body">
            <div class="btn-container">
              <label class="text-commerm">Fecha y hora de Expiración:</label>
              <label class="text-rest">{{paylinkDetails?.expirationDate |date:'dd/MM/yyyy HH:mm:ss' }}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Enlace de Pago:</label>
              <label class="text-rest">{{paylinkDetails?.linkUrl}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Número de Transacción:</label>
              <label
                class="text-rest">{{paylinkDetails?.purchaseNumber}}</label>
            </div>

            <div class="btn-container">
              <label class="text-commerm">Estado de la Orden:</label>
              <span
                [ngClass]="[getStatusClass(paylinkDetails?.orderStatus), 'no-background']">
                <label class="text-center-status">{{
                  getStatusInSpanish(paylinkDetails?.orderStatus) }}</label>
              </span>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- Modal Footer -->
    <div class="modal-footer d-flex justify-content-center">
      <button type="button" class="btn btn-outline-secondary"
        (click)="activeModal.close('Close click')">Cerrar</button>
    </div>

  </div>
</multitenant-base-modal>