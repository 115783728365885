<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
              {{'home.footer' | translate}} © {{year}} Red Pontis. {{'home.reserved'| translate }}
            </div>
            <div class="col-sm-6">
              <div class="text-sm-end d-none d-sm-block">
                {{'home.made' | translate }}  <i class="mdi mdi-heart text-danger"></i> {{'home.by' | translate}} <a href="https://redpontis.com" target="_blank">Red Pontis</a>
              </div>
            </div>
        </div>
    </div>
</footer>
