<div id="layout-wrapper">
  <multitenant-horizontaltopbar (mobileMenuButtonClicked)="onToggleMobileMenu()"></multitenant-horizontaltopbar>
  <div class="d-lg-none">
    <!-- <multitenant-sidebar [menuItems]="menuItems$ | async" [isCondensed]="isCondensed"></multitenant-sidebar> -->
  </div>
  <div class="main-content">
    <div class="page-content">
      <!-- <multitenant-breadcrumb></multitenant-breadcrumb> -->
      <ng-content></ng-content>
    </div>
  </div>

  <multitenant-footer></multitenant-footer>
  <multitenant-rightsidebar></multitenant-rightsidebar>
</div>
