import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { TenantService } from '@shared/services/tenant.service';
import { Plan } from '../models/form.models';
import { AlertsService } from '@shared/services/alerts.service';

@Injectable({
    providedIn: 'root',
})
export class PlansServices {

    private http = inject(HttpClient);
    private tenantService = inject(TenantService);
    private alerts = inject(AlertsService);

    createPlans(data: Plan): Observable<Plan> {
        let endpoint = `${this.tenantService.api}/plans`;
        return this.http.post<Plan>(endpoint, data).pipe(
            catchError(error => {
                this.alerts.error(`Error: ${error.status}`, 'Error al crear planes');
                return throwError(() => error);
            })
        );
    }

    idPlans(plantId: string, merchantId: string): Observable<Plan> {
        const url = `${this.tenantService.api}/plans/${plantId}/${merchantId}`;
        return this.http.get<{ message: string; code: number; data: Plan }>(url).pipe(
            map(response => response.data),
            catchError(error => {
                this.alerts.error(`Error: ${error.status}`, 'Error al obtener el id del plan');
                return throwError(() => error);
            })
        );
    }


    updatePlan(data: Plan): Observable<Plan> {
        let endpoint = `${this.tenantService.api}/plans`;
        return this.http.put<Plan>(endpoint, data).pipe(
            catchError(error => {
                this.alerts.error(`Error: ${error.status}`, 'Error al actualizar plan');
                return throwError(() => error);
            })
        );
    }


    getListPlans(merchantId: string) {
        const url = `${this.tenantService.api}/plan-rec/list/${merchantId}`;
        // Body POST
        const body = {
            limit: 10,
            page: 1,
            criteria: [],
            orderBy: {
                field: "createdOn",
                type: "desc"
            }
        };

        return this.http.post<{ list: Plan[] }>(url, body).pipe(
            map(response => response.list),
            catchError(error => {
                this.alerts.error(`Error: ${error.status}`, 'Error al obtener la lista de planes');
                return throwError(() => error);
            })
        );
    }
}