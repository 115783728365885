import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { CatalogService } from "@shared/services/catalog.service";
import { UserService } from "@shared/services/user.service";
import { Observable, throwError } from "rxjs";
import { CreatePayLinkResponse } from "../models/payment.models";
import { PaylinkDetails } from "@shared/models/paylink-details-models";
import { catchError,finalize } from 'rxjs/operators';
import {AlertsService} from "@shared/services/alerts.service";


@Injectable({
  providedIn: "root",
})
export class Paymentservices {

  private http = inject(HttpClient);
  private catalog = inject(CatalogService);
  private userService = inject(UserService);
  private alert = inject(AlertsService);

  newLink(data: any): Observable<CreatePayLinkResponse> {
    const user = this.userService.currentUser;
    let endpoint = `${this.catalog.serverApi}/paylink`;
    if (user.isMerchantUser) {
      endpoint = `${endpoint}/${user.chosenMerchant.merchantId}`;
    }
    return this.http.post<CreatePayLinkResponse>(endpoint, data).pipe(
      catchError((error) => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      })
    );
  }

  editLink(Id: string, data: any): Observable<CreatePayLinkResponse> {
    const user = this.userService.currentUser;
    let endpoint = `${this.catalog.serverApi}/paylink`;
    if (user.isMerchantUser) {
      endpoint = `${endpoint}/${user.chosenMerchant.merchantId}`;
    }
    endpoint = `${endpoint}/${Id}`;
    return this.http.put<CreatePayLinkResponse>(endpoint, data).pipe(
      catchError(error => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      })
    );
  }

  getLink(Id: string): Observable<any> {
    return this.http.get<any>(`/api/paylink/${Id}`);
  }

  getPaylinkDetails(merchantId: string, orderId: string): Observable<PaylinkDetails | undefined> {
    const url = `${this.catalog.serverApi}/paylink/${merchantId}/${orderId}`;
    return this.http.get<PaylinkDetails>(url).pipe(
      catchError((error) => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      }),
    );
  }


}
