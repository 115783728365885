import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  TemplateRef,
  ViewChildren,
  QueryList
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';

export type EndpointType = string | (() => string);

@Component({
  selector: 'elibrito-lazy-loading-select',
  templateUrl: './lazy-loading-select.component.html',
  styleUrls: ['./lazy-loading-select.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LazyLoadingSelectComponent,
      multi: true,
    },
  ],
})
export class LazyLoadingSelectComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() endpoint: EndpointType;
  @Input() pageSize = 10;
  @Input() debounceTime = 700;
  @Input() searchKey = 'q';
  @Input() totalItemsKey = 'count';
  @Input() idKey = 'id';
  @Input() displayKey = 'name';
  @Input() imgKey = '';
  @Input() placeholder = '';
  @Input() pageQueryparam = 'page';
  @Input() pageSizeQueryparam = 'pageSize';
  @Input() multiple = false;
  @Input() readonly = false;
  @Input() formControlName!: string;
  @Input() disabled: boolean;
  @Input() loadSelectedElement: (ids: any) => Observable<any[]>;
  @Input() map: (response: any, index?: number) => any;
  @Output() optionSelected = new EventEmitter<any>();
  @Output() optionDeselected = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('selectContainer') selectContainer: ElementRef;
  @ViewChild('selectOptionsTemplate', { static: true }) selectOptionsTemplate!: TemplateRef<any>;
  @ViewChildren('focusableItem') focusableItems: QueryList<ElementRef>;

  options: any[] = [];
  loading = false;
  searchTerm = '';
  currentPage = 1;
  totalItems = 0;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private controlContainer: ControlContainer
  ) {}

  ngAfterViewInit(): void { 
    this.initSearch();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  fetchOptions(): void {
    const params = {
      [this.pageQueryparam]: this.currentPage,
      [this.pageSizeQueryparam]: this.pageSize,
      [this.searchKey]: this.searchTerm
    };

    this.loading = true;
    let endpoint: string;
    if (typeof this.endpoint === 'string') {
      endpoint = this.endpoint;
    } else {
      endpoint = this.endpoint();
    }
    let call = this.http.get(endpoint, { params })
      .pipe(take(1));

      if(this.map) {
        call = call.pipe(map(this.map));
      }

    call.subscribe((response: any) => {
        const newOptions = response;
        const newTotalItems = response[this.totalItemsKey];
        this.options = this.options.concat(newOptions);
        this.totalItems = newTotalItems;
        this.loading = false;
      });
  }
  private initSearch(): void {
    this.currentPage = 1;
    this.options = [];
    this.loading = true;
    this.fetchOptions();
  }

  customSearchFn = (term: string, item: any) => {
    term = term.toLowerCase();
    return [this.idKey, this.displayKey].some(key => item[key]?.toLowerCase().includes(term));
  };
  
  writeValue(value: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
}
