import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of, throwError } from 'rxjs';
import { CatalogService } from './catalog.service';
import Swal from 'sweetalert2';


@Injectable({
    providedIn: 'root'
})
export class AnullmentService {

    private http = inject(HttpClient);
    private catalog = inject(CatalogService);

    deleteAnullmente(merchantId: string, saleId: string): Observable<any | undefined> {
        const url = `${this.catalog.serverApi}/sale/annulment/${merchantId}/${saleId}`;
        return this.http.delete<any>(url).pipe(
            catchError(error => {
                Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al anular la transacción',
                    showConfirmButton: false,
                    timer: 3500
                });
                return throwError(() => error);
            })
        );
    }

}