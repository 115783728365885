<multitenant-base-modal>
    <div
      class="modal-title d-flex align-items-center mb-2 justify-content-center">
      <i class="bx bx-layer text-success small-icon"></i>
      <h4 class="ms-2 mb-0 custom-button">Detalle del Plan</h4>
    </div>
  
    <div class="body-container">
      <div class="tab-content" id="nav-tabContent"
        style="position: relative;top: 10px;">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel"
          aria-labelledby="nav-home-tab">
          <div class="card colors-cards">
            <p class="tab-text-commerm">Datos del Plan</p>
            <div class="card-body">
              <div class="btn-container">
                <label class="text-commerm">Nombre del Plan:</label>
                <label
                  class="text-rest">{{ listPlans?.name }}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Producto Asociado:</label>
                <label class="text-ruc">
                  <div *ngIf="listPlans?.products?.length > 0; else noProducts">
                    <ng-container *ngFor="let product of listPlans.products; let last = last">
                      {{ product.planName }}<span *ngIf="!last">, </span>
                    </ng-container>
                  </div>
                  
                <ng-template #noProducts>
                    <label>N/A</label>
                </ng-template>
                </label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Días de Prueba:</label>
                <label class="text-ruc">{{ listPlans?.trialDays }}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Monto del Plan:</label>
                <label class="text-ruc">
                  {{ getCurrencySymbol(listPlans?.currencyId) }} {{ formatAmount(listPlans?.amount, listPlans?.currencyId) }}
               
                </label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Ciclo de Facturación:</label>
                <label class="text-ruc">{{ getFrequencyInSpanish(listPlans?.frequency) }}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Estado:</label>
                <span [ngClass]="[getStatusClass(listPlans?.status),'no-background']">
                  <label class="text-center-status">{{getStatusInSpanish(listPlans?.status)}}</label> </span>
                
              </div>
             
            </div>
          </div>
       
        </div>
      </div>
    </div>
    
    <div class="modal-footer justify-content-center">
      <button type="button" class="btn btn-outline-secondary"
        (click)="activeModal.close('Close click')">Cerrar</button>
     
        
    </div>
  
  </multitenant-base-modal>

