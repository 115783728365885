import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralStatus, STATUS_CLASS_MAP, STATUS_TRANSLATIONS } from '@shared/models/enum-status.models';
import { RechargeDetails } from '@shared/models/recharge.models';
import { AlertsService } from '@shared/services/alerts.service';
import { CurrencyService } from '@shared/services/currency.service';
import { RechargeServices } from 'src/app/tenant/transactions/services/recharge.service';


@Component({
  selector: 'app-reversed-balance',
  templateUrl: './modal-reversed-balance.component.html',
  styleUrls: ['./modal-reversed-balance.component.scss']
})
export class ModalReversedBalanceComponent implements OnInit {
  @Output() transactionReversed = new EventEmitter<void>();
  @Input() transactionId!: string;
  @Input() externalId!: string;

  isTransfer: boolean = false;
  rechargeDetails!: RechargeDetails;
  rechargeReversed!: RechargeDetails;


  public activeModal = inject(NgbActiveModal);
  private alertsService = inject(AlertsService);
  private currencyPipe = inject(CurrencyPipe);
  private currencyService = inject(CurrencyService);
  private rechargeServices = inject(RechargeServices);


  ngOnInit(): void {
    this.getDetailsRecharge();
  }

  getDetailsRecharge(): void {
    this.rechargeServices.detailsRecharge(this.transactionId).
      subscribe(data => {
        this.rechargeDetails = data;
      });
  }

  getCurrencySymbol(currencyCode: string): string {
    return this.currencyService.getCurrencySymbol(currencyCode);
  }

  formatAmount(value: number, currency: string): string {
    if (currency === 'PYG') {
      return parseFloat(value.toString()).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return this.currencyPipe.transform(value?.toString(), '', '', '1.2-2');
  }

  translateOperation(operation: string): string {
    const operationLower = operation?.toLowerCase();
    if (operationLower === 'recharge') {
      return 'Recarga';
    }
    return operation;
  }

  public getStatusClass(status: GeneralStatus): string {
    return STATUS_CLASS_MAP[status] || "badge-soft-primary";
  }

  public getStatusInSpanish(status: GeneralStatus): string {
    return STATUS_TRANSLATIONS[status] || "Desconocido";
  }

  checkIfAnulled(refills: RechargeDetails): boolean {
    // Aquí asumimos que ya fue reembolsada
    return refills?.status === 'REVERSED';
  }

  onAccept() {
    if (this.checkIfAnulled(this.rechargeReversed)) {
      this.alertsService.error('Esta recarga ya ha sido reembolsada');
    } else {
      this.transactionReversed.emit(); // Notifica al componente padre que se debe procesar el reembolso
      this.activeModal.close();
    }
  }



}
