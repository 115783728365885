export enum UserType {
  ACQUIRER_ADMIN = 'acquirer-admin',
  ACQUIRER_OPERATOR = 'acquirer-operator',
  MERCHANT_ADMIN = 'merchant-admin',
  MERCHANT_OPERATOR = 'merchant-operator',

  transmitter= 'istransmitter',
}
  
  export class User {
    email: string;
    fullName: string;
    role: UserType;
    isAcquirerAdmin?: boolean;
    isMerchantAdmin?: boolean;
    istransmitter?: boolean;
    merchants?: merchant[];
    chosenMerchant?: merchant;
    isAcquirerOperator?: boolean;
    isMerchantOperator?: boolean;
   };
  export interface merchant {
    merchantId: string;
    name: string;
  }
  
  export interface UserCredentials {
    username: string;
    password: string;
  }
  