import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsService } from '@shared/services/alerts.service';
import { TenantService } from '@shared/services/tenant.service';

@Component({
  selector: 'app-file-uploader',
  template: `
    <div class="d-flex gap-2">
      <input type="text" class="form-control" [value]="fileName || 'Seleccione un archivo'" readonly>
      <label class="btn btn-outline-secondary">
      <input type="file" #fileInput accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="display: none;" hidden (change)="onFileSelected($event)">

        📂
      </label>
  
      <button class="btn btn-outline-primary" type="button" (click)="validateFile()">
        Validar Archivo
      </button>
    </div>
    
    <div *ngIf="isValidated" class="alert alert-primary border mt-3">
    <!-- Se cargó la información. Descarga 
    <button class="btn btn-link p-0" type="button" (click)="downloadErrorFile()">
      aquí
    </button>  -->
   Se validaron los datos de tu archivo xlsx.
  </div>
  
    <div class="d-flex justify-content-end gap-2 mt-3">
      <button class="btn btn-primary" [disabled]="!isValidated" (click)="importFile()">Importar</button>
      <button *ngIf="showCancelButton" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
    </div>
`,
  styleUrl: './file-uploader.component.scss'
})

export class FileUploaderComponent {

  @Input() baseCancelUrl: string = '';
  @Input() acceptedFormats: string = '.xlsx';
  @Input() showCancelButton: boolean = true;
  @Output() fileImported = new EventEmitter<{ fileName: string; file: File }>();
  @Output() fileValidated = new EventEmitter<boolean>();
  @Output() downloadFile = new EventEmitter<void>();
  @Output() fileImportRequested = new EventEmitter<void>();

  fileObjectSelected: File | null = null;

  fileName: string = '';
  isValidated: boolean = false;

  private alerts = inject(AlertsService);
  private tenantService = inject(TenantService);
  private router = inject(Router);

  get cancelUrl(): string {
    return `/${this.tenantService.tenantName}${this.baseCancelUrl}`;
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      this.fileObjectSelected = file;
      this.fileImported.emit({ fileName: this.fileName, file });
      this.isValidated = false;
    }
  }

  validateFile() {
    if (!this.fileName) {
      this.alerts.error('No se ha seleccionado un archivo.');
      this.isValidated = false;
      return;
    }
    if (!this.fileName.toLowerCase().endsWith('.xlsx')) {
      this.alerts.error(`Solo se permiten archivos .xlsx`);
      this.isValidated = false;
    } else {
      this.isValidated = true;
      this.fileValidated.emit(true);
    }
  }

  importFile() {
    if (this.isValidated && this.fileObjectSelected) { 
      this.fileImportRequested.emit();
      // this.fileImported.emit({ fileName: this.fileName, file: this.fileObjectSelected });
    } else {
      this.alerts.error('No hay archivo seleccionado.');
    }
  }
  

  downloadErrorFile() {
    this.downloadFile.emit();
  }

  cancel() {
    this.router.navigate([this.cancelUrl]);
  }
}
