export enum StatusUeno {
    AUTHORIZED = "AUTHORIZED",
    REVERSED = "REVERSED",
    DENIED = "DENIED",
   
  };

  export enum StatusTransaction {
    AUTHORIZED = "AUTHORIZED",
    UNEXPECTED = "UNEXPECTED",
    DENIED = "DENIED",
    VOIDED = "VOIDED",
  };


 export enum StatusPaylink {
    COMPLETED = "COMPLETED",
    PENDING = "PENDING",
    EXPIRED = "EXPIRED",
    VOIDED = "VOIDED",
  };

 export enum CardStatus {
     ACTIVE = "ACTIVE",
     UNASSIGNED = "UNASSIGNED",
     BLOCKED = "BLOCKED",
 };

 export const STATUS_CLASS_MAP_CARDS: { [key in CardStatus]: string } = {
  [CardStatus.ACTIVE]: "badge-soft-success",
  [CardStatus.UNASSIGNED]: "badge-soft-warning",
  [CardStatus.BLOCKED]: "badge-soft-danger",

};

  
  export const STATUS_CLASS_MAP_PAYLINK: { [key in StatusPaylink]: string } = {
    [StatusPaylink.COMPLETED]: "badge-soft-success",
    [StatusPaylink.PENDING]: "badge-soft-warning",
    [StatusPaylink.EXPIRED]: "badge-soft-danger",
    [StatusPaylink.VOIDED]: "badge-soft-info",
  };
  
  
   export const STATUS_CLASS_MAP_TRANSACTION: { [key in StatusTransaction]: string } = {
    [StatusTransaction.AUTHORIZED]: "badge-soft-success",
    [StatusTransaction.UNEXPECTED]: "badge-soft-primary",
    [StatusTransaction.DENIED]: "badge-soft-danger",
    [StatusTransaction.VOIDED]: "badge-soft-info",
  };
  
  
  export const STATUS_CLASS_MAP: { [key in StatusUeno]: string } = {
    [StatusUeno.AUTHORIZED]: "badge-soft-success",
    [StatusUeno.REVERSED]: "badge-soft-primary",
    [StatusUeno.DENIED]: "badge-soft-danger",
   
  };