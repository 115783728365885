export enum GeneralStatus {
    AUTHORIZED = "AUTHORIZED",
    REVERSED = "REVERSED",
    DENIED = "DENIED",
    UNEXPECTED = "UNEXPECTED",
    VOIDED = "VOIDED",
    PENDING = "PENDING",
    ACTIVE = "ACTIVE",
    SUSPENDED = "SUSPENDED",
    COMPLETED = "COMPLETED",
    EXPIRED = "EXPIRED",
    UNASSIGNED = "UNASSIGNED",
    BLOCKED = "BLOCKED",
    CANCELED = "CANCELED",
    TRIAL = "TRIAL",
    TRUE = "true",
    FALSE = "false",
    PROCESS = "PROCESS",
    ENDED = "ENDED",
    ERROR = "ERROR",
    CREATED = "CREATED",
    PROCESSING = "PROCESSING",
    PAUSED = "PAUSED",
    UPDATING = "UPDATING"
  }
  
  export const STATUS_CLASS_MAP: { [key in GeneralStatus]?: string } = {
    [GeneralStatus.AUTHORIZED]: "badge-soft-success",
    [GeneralStatus.REVERSED]: "badge-soft-primary",
    [GeneralStatus.DENIED]: "badge-soft-danger",
    [GeneralStatus.UNEXPECTED]: "badge-soft-primary",
    [GeneralStatus.VOIDED]: "badge-soft-info",
    [GeneralStatus.PENDING]: "badge-soft-warning",
    [GeneralStatus.ACTIVE]: "badge-soft-success",
    [GeneralStatus.SUSPENDED]: "badge-soft-secondary",
    [GeneralStatus.COMPLETED]: "badge-soft-success",
    [GeneralStatus.EXPIRED]: "badge-soft-danger",
    [GeneralStatus.UNASSIGNED]: "badge-soft-warning",
    [GeneralStatus.BLOCKED]: "badge-soft-danger",
    [GeneralStatus.TRUE]: "badge-soft-success",
    [GeneralStatus.FALSE]: "badge-soft-danger",
    [GeneralStatus.CANCELED]: "badge-soft-danger",
    [GeneralStatus.TRIAL]: "badge-soft-info",
    [GeneralStatus.PROCESS]: "badge-soft-warning",
    [GeneralStatus.ENDED]: "badge-soft-success",
    [GeneralStatus.ERROR]: "badge-soft-danger",
    [GeneralStatus.CREATED]: "badge-soft-primary",
    [GeneralStatus.PROCESSING]: "badge-soft-warning",
    [GeneralStatus.UPDATING]: "badge-soft-info",
    [GeneralStatus.PAUSED]: "badge-soft-warning",

  };
  
  export const STATUS_TRANSLATIONS: { [key in GeneralStatus]?: string } = {
    [GeneralStatus.AUTHORIZED]: "Autorizado",
    [GeneralStatus.REVERSED]: "Anulado",
    [GeneralStatus.DENIED]: "Denegado",
    [GeneralStatus.UNEXPECTED]: "Desconocido",
    [GeneralStatus.VOIDED]: "Anulado",
    [GeneralStatus.PENDING]: "Pendiente",
    [GeneralStatus.ACTIVE]: "Activo",
    [GeneralStatus.SUSPENDED]: "Suspendido",
    [GeneralStatus.COMPLETED]: "Completado",
    [GeneralStatus.EXPIRED]: "Expirado",
    [GeneralStatus.UNASSIGNED]: "Sin asignar",
    [GeneralStatus.BLOCKED]: "Bloqueado",
    [GeneralStatus.TRUE]: "Activo",
    [GeneralStatus.FALSE]: "Inactivo",
    [GeneralStatus.CANCELED]: "Cancelado",
    [GeneralStatus.TRIAL]: "Prueba",
    [GeneralStatus.PROCESS]: "Procesando",
    [GeneralStatus.ENDED]: "Finalizado",
    [GeneralStatus.ERROR]: "ERROR",
    [GeneralStatus.PAUSED]: "Pausado",
    [GeneralStatus.UPDATING]: "Actualizacón",
    [GeneralStatus.PROCESSING]: "Procesando",
  };
  