import { Component, Input, OnInit, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AlertsService } from "@shared/services/alerts.service";
import { ClipboardService } from 'ngx-clipboard';
import * as QRCode from "qrcode";


@Component({
  selector: "app-modal-detail-pay-link",
  templateUrl: "./modal-detail-pay-link.component.html",
  styleUrls: ["./modal-detail-pay-link.component.scss"],
})
export class ModalDetailPayLinkComponent implements OnInit {
  @Input() init: "create" | "preview";
  @Input() row: any;
  @Input() url: string = "";
  linkForm: FormGroup;
  qrCodeDataUrl: string | null = null;


  constructor(private fb: FormBuilder,
    private alerts: AlertsService,
    private clipboardService: ClipboardService
  ) {
    this.linkForm = this.fb.group({
      link: [{ value: "", disabled: true }],
    });
  }

  ngOnInit(): void {
    this.url && this.linkForm.patchValue({ link: this.url });
  }

  copyLink() {
    if (this.linkForm.get("link")?.value) {
      this.clipboardService.copy(this.linkForm.get("link")?.value);
      this.alerts.success("Se copió en portapapeles", this.linkForm.get("link")?.value);
    }
  }


  generateQRCode() {
    const link = this.linkForm.get("link")?.value;
    if (link) {
      QRCode.toDataURL(link)
        .then((url) => {
          this.qrCodeDataUrl = url;
        })
        .catch((err) => {
          console.error(err);
          this.qrCodeDataUrl = null;
        });
    } else {
      this.qrCodeDataUrl = null;
    }
  }

  onSubmit() { }
}
