import { Injectable } from '@angular/core';
import { Observable, map, of, take } from 'rxjs';
import { TenantInformation, DEFAULT_LOGO_DARK, DEFAULT_LOGO_LIGHT } from '@shared/models/tenant.models';
import { HttpClient, HttpContext } from '@angular/common/http';
import { CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider';
import { BYPASS_LOG } from '@shared/interceptors/http-token.interceptor';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  public cognitoClient: CognitoIdentityProviderClient;
  libraryPk: number;
  tenantName: string;
  currentClient: string;
  tenantInformation: TenantInformation;
  themeLoaded = false;

  constructor(
    private http: HttpClient,
  ) { }

  setStyleTheme(serverApi: string): void {
    const head = document.getElementsByTagName('head')[0];

    const style = document.createElement('link');
    style.type = 'text/css';
    style.rel = 'stylesheet';
    // TODO:// Implement the following code when the API is ready
    // style.href = `${serverApi}/theme_variables/`;
    if(serverApi.includes('supefina')){
      style.href = 'assets/theme/supefina.css';
    }
    if(serverApi.includes('bbva')){
      style.href = 'assets/theme/bbva.css';
    }
    if(serverApi.includes('ueno')){
      style.href = 'assets/theme/ueno.css';
    }
    style.onload = () => {
      this.themeLoaded = true;
    };
    head.appendChild(style);
  }

  setCompanyInformation(client: string, serverApi: string) {
    this.getCompanyInformation(client, serverApi).pipe(take(1)).subscribe((companyInformation) => {
      this.tenantInformation = companyInformation;
      this.cognitoClient =  new CognitoIdentityProviderClient({ region: companyInformation.cognitoRegion})
    });
  }

  getCompanyInformation(client: string, serverApi: string): Observable<TenantInformation> {
    if (this.currentClient && client === this.currentClient && this.tenantInformation) {
        return of(this.tenantInformation);
    }
    const options = {context: new HttpContext().set(BYPASS_LOG, true)};
    return this.http.get<TenantInformation>(`${serverApi}/tenant/config/`, options)
        .pipe(map((response) => {
            this.tenantInformation = this.processTenantInformation(response);
            return this.tenantInformation;
        }));
  }

  private processTenantInformation(companyInformation): TenantInformation {
    if (!companyInformation.logoDark) {
        companyInformation.logoDark = DEFAULT_LOGO_DARK;
    }
    if (!companyInformation.logoLight) {
        companyInformation.logoLight = DEFAULT_LOGO_LIGHT;
    }
    if(companyInformation['cognito.client.id']){
      companyInformation.cognitoPoolId = companyInformation['cognito.client.id'];
      delete companyInformation['cognito.client.id'];
    }
    if(companyInformation['cognito.region']){
      companyInformation.cognitoRegion = companyInformation['cognito.region'];
      delete companyInformation['cognito.region']
    }
    return companyInformation;
  }
}
