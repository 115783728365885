import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { TenantService } from './tenant.service';
import { AlertsService } from './alerts.service';


@Injectable({
    providedIn: 'root'
})
export class AnullmentService {

    private http = inject(HttpClient);
    private tenantService = inject(TenantService);
    private alerts = inject(AlertsService);

    deleteAnullmente(merchantId: string, saleId: string): Observable<any | undefined> {
        const url = `${this.tenantService.api}/sale/annulment/${merchantId}/${saleId}`;
        return this.http.delete<any>(url).pipe(
            catchError(error => {
                this.alerts.error(`Error: ${error.status}`, 'Error al anular la transacción');
                return throwError(() => error);
            })
        );
    }

}