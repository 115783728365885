<multitenant-base-modal>
    <!-- Modal Header -->
    <div class="modal-title d-flex align-items-center mb-2 justify-content-center text-center">
      <i class="fas fa-money-check-alt text-success small-icon"></i>
      <h4 class="ms-2 mb-0 custom-button">Detalle del log</h4>
    </div>
  
    <!-- Modal Body -->
    <div class="body-container">
      <div class="row gy-3">
        <!-- Request Card -->
        <div class="col-12 col-lg-6">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex flex-column flex-md-row align-items-start justify-content-between">
                 <h6 class="text-muted">Request Details</h6>
              </div>
              <textarea 
              class="form-control" 
              rows="7" 
              readonly 
            >
              {{ logDetails.request }}
            </textarea>
            </div>
          </div>
        </div>
  
        <!-- Response Card -->
        <div class="col-12 col-lg-6">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex flex-column flex-md-row align-items-start justify-content-between">
                <h6 class="text-muted">Response Details</h6>
              </div>
            
                <textarea 
                class="form-control" 
                rows="7" 
                readonly 
              >
                {{ logDetails.response }}
              </textarea>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Modal Footer -->
    <div class="modal-footer justify-content-center">
      <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">
        Cerrar
      </button>
    </div>
  </multitenant-base-modal>
  