import {
  Component,
  OnInit,
  Input,
} from "@angular/core";
import { Location } from "@angular/common";

import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() title: string = "";

  constructor(
    private router: Router,
     private route: ActivatedRoute,
     private location: Location
    ) {}

  ngOnInit(): void {}
  


  goBack(): void {
    // this.router.navigate([".."], { relativeTo: this.route });
    this.location.back();
  }
}