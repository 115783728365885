
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TenantService } from '@shared/services/tenant.service';
import { FileProcessing } from '../models/charges.models';
import { AlertsService } from '@shared/services/alerts.service';


@Injectable({
  providedIn: 'root'
})
export class ChargesService {

  private tenantService = inject(TenantService);
  private http = inject(HttpClient);
  private alerts = inject(AlertsService);

  urlPresignedCharges(merchantId: string, fileName: string): Observable<any> {
    const url = `${this.tenantService.api}/merchant/presigned-url`;
    const body = {
      relativePath: `${merchantId}/charge/in/${fileName}`
    };
    return this.http.post<any>(url, body).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al subir archivo');
        return throwError(() => new Error(error));
      })
    )
  }

  getProcesFile(codeLot: string): Observable<FileProcessing | undefined> {
    const url = `${this.tenantService.api}/file?uuid=${codeLot}`;
    return this.http.get<FileProcessing>(url).pipe(
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al obtener los cargos');
        return throwError(() => new Error(error));
      })
    );
  }

  urlDownloadCharges(merchantId: string, type: string, fileName: string): Observable<any> {
    const url = `${this.tenantService.api}/merchant/download`;
    const body = {
      relativePath: `${merchantId}/${type}/out/${fileName}`
    };
    return this.http.post<{ url: string }>(url, body).pipe(
      switchMap((response) => {
        const fileUrl = response.url;
        return this.http.get(fileUrl, { responseType: 'blob' });
      }),
      catchError(error => {
        this.alerts.error(`Error: ${error.status}`, 'Error al descargar archivo');
        return throwError(() => new Error(error));
      })
    )
  }

}



