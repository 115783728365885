
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
  template: `
    <button type="button" class="btn btn-rounded btn-{{params.buttonType}} w-sm btn-sm text-truncate" (click)="onClick()">
    <i *ngIf="params.iconClass" class="{{params.iconClass}}"></i>
      {{params.label}}
  </button>

    `,

})
export class ButtonCellRendererComponent implements ICellRendererAngularComp {
  public params!: any;
  agInit(params: any): void {
    this.params = params;
    this.params.buttonType = this.params.buttonType ?? "primary";
    this.processParamStringValue(params, 'buttonType');
    this.processParamStringValue(params, 'iconClass');
  }


  refresh(): boolean {
    return true;
  }

  onClick() {
    this.params.onClick(this.params.data);
  }

  private processParamStringValue(params: any, paramName: string): void {
    if (params[paramName] && typeof params[paramName] === 'function') {
      params[paramName] = params[paramName](params);
    }
  }


}