import { Component, inject, OnInit } from '@angular/core';
import { User } from '@shared/models/user.models';
import { TenantService } from '@shared/services/tenant.service';
import { UserService } from '@shared/services/user.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
  styleUrls: ['./dropdwon-cell-renderer.component.scss'],
  template: `
  <select id="dropdown" name="options" (change)="onSelectChange($event)">
        <option value=""  selected>Acciones</option>
        @if (params.status !== 'UNASSIGNED') {
        <option value="option1">
         <label>{{params.label}}</label> 
        </option>
        }
        @if (isMerchantUser && params.showAnular === false && params.status !== 'VOIDED'  && params.status !== 'DENIED'  && params.status !== 'UNEXPECTED') {
        <option value="option2">
               <label>Anular</label>
        </option>
            }
            @else if (params.showLink === false) {
        <option value="option3">
               <label>Detalle</label>
        </option>
            }
            @else if  (params.showEdit === false) {
        <option value="option4">
               <label>Modificar</label>
        </option>
            }
            @else if  (params.status !== 'UNASSIGNED' && params.showtransfer === false) {
        <option value="option5">
               <label>Transferir saldo</label>
        </option>
            }
            @else if(params.status !== 'REVERSED' && params.showReversed === false) {
        <option value="option6">
               <label>Anular</label>
        </option>
            }
    
    
  </select>
  `,

 
})
export class DropdwonCellRendererComponent implements ICellRendererAngularComp, OnInit {
  public params!: any;
  isMerchantUser: boolean = false;
  isAcquiringUser: boolean = false;

  private userService = inject(UserService);
  private tenantService= inject(TenantService);

  agInit(params: any): void {
    this.params = params;
    this.params.buttonType = this.params.buttonType ?? "primary";
  }

  ngOnInit(): void {
    
    this.userService.currentUser$.subscribe((user: User) => {
      this.isMerchantUser = user?.isMerchantUser || false;
      this.isAcquiringUser = user?.isAcquiringUser || false;
    });
  }

  refresh(): boolean {
    return true;
  }

  onClick() {
    this.params.onClick(this.params.data);
  }

  onClickLink() {
    this.params.onClickLink(this.params.data);
  }
  onEdit() {
    this.params.onEdit(this.params.data)
  }

  trnsferBalance(){
    this.params.trnsferBalance(this.params.data)
  }

  onSelectChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    let selectedValue = selectElement.value;
    if (selectedValue === 'option1') {
      this.onClick(); 
    } else if (selectedValue === 'option2') {
      this.onClickLink();  
    } else if (selectedValue === 'option3') {
      this.onClickLink();  
    } else if (selectedValue === 'option4') {
      this.onEdit();
    } else if (selectedValue === 'option5') {
      this.trnsferBalance();
    } else if (selectedValue === 'option6') {
      this.onClickLink(); 
    }
    setTimeout(() => selectElement.value = '', 0);
  }



}
