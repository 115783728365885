import { Component, Input, OnInit, inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Paymentservices } from "src/app/tenant/payment/services/payment.service";
import { PaylinkDetails } from "@shared/models/paylink-details-models";
import { CurrencyService } from "@shared/services/currency.service";
import { CurrencyPipe } from "@angular/common";
import { GeneralStatus, STATUS_CLASS_MAP, STATUS_TRANSLATIONS } from "@shared/models/enum-status.models";


@Component({
  selector: "app-moda-detail-paylink",
  templateUrl: "./moda-detail-paylink.component.html",
  styleUrls: ["./moda-detail-paylink.component.scss"],
})
export class ModaDetailPaylinkComponent implements OnInit {
  @Input() merchantId!: string;
  @Input() orderId!: string;
  paylinkDetails!: PaylinkDetails;


  public activeModal = inject(NgbActiveModal);
  private paymentServices = inject(Paymentservices);
  private currencyService = inject(CurrencyService);
  private currencyPipe = inject(CurrencyPipe);

  ngOnInit(): void {
    this.getPaylinkDetails();
  }

  getPaylinkDetails() {
    this.paymentServices.getPaylinkDetails(this.merchantId, this.orderId).subscribe((details) => {
      this.paylinkDetails = details;
    });
  }

  public getStatusClass(status: GeneralStatus): string {
    return STATUS_CLASS_MAP[status] || "badge-soft-primary";
  }

  public getStatusInSpanish(status: GeneralStatus): string {
    return STATUS_TRANSLATIONS[status] || "Desconocido";
  }

  getCurrencySymbol(currency: string): string {
    return this.currencyService.getCurrencySymbol(currency);
  }

  formatAmount(value: number, currency: string): string {
    if (currency === 'PYG') {
      return parseFloat(value.toString()).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return this.currencyPipe.transform(value?.toString(), '', '', '1.2-2');
  }
}



