<multitenant-base-modal>
  <div class="modal-title d-flex align-items-center mb-2 justify-content-center">
    <i class="fas fa-store text-success small-icon"></i>
    <h4 class="ms-2 mb-0 custom-button">Detalle del Comercio</h4>
  </div>
  <div class="body-container">
    <div class="tab-content" id="nav-tabContent" style="position: relative;top: 10px;">
      <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
        <div class="card colors-cards">
          <p class="tab-text-commerm">Datos del comercio</p>
          <div class="card-body">
            <div class="btn-container">
              <label class="text-commerm">Código de Comercio:</label>
              <label class="text-rest">{{merchantDetails?.merchantId}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Razón Social:</label>
              <label class="text-rest">{{merchantDetails?.merchantName}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Ruc:</label>
              <label class="text-ruc">{{merchantDetails?.businessId}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Dirección:</label>
              <label class="text-addres">{{merchantDetails?.businessAddress}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Correo:</label>
              <label class="text-email">{{merchantDetails?.businessEmail}}</label>
            </div>

            <div class="btn-container">
              <label class="text-commerm">Grupo de Comercio:</label>
              <label class="text-email">{{merchantDetails?.groupId}}</label>
            </div>

          </div>
        </div>
      </div> 
    </div> 
  </div> 

  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Close click')">Cerrar</button>
  </div>
</multitenant-base-modal>