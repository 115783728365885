import { APP_INITIALIZER, inject, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyPipe } from '@angular/common';
 

// Swiper Slider
import { SlickCarouselModule } from 'ngx-slick-carousel';


// Page Route

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Auth
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutsModule } from '@layouts/layouts.module';
import { PagesModule } from './pages/pages.module';
import { LanguageService } from '@shared/services/language.service';
import { HttpTokenInterceptor } from '@shared/interceptors/http-token.interceptor';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from '@shared/services/date.service';
import { AuthModule, LogLevel, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { AuthService } from '@shared/services/auth.service';
import { from, map, of } from 'rxjs';
import { TenantService } from '@shared/services/tenant.service';



export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}


defineLocale('es', esLocale);
const I18N_VALUES = {
  es: {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    weekLabel: 'sem',
  },
};

@Injectable()
export class I18n {
	language = 'es';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
	private _i18n = inject(I18n);

	getWeekdayLabel(weekday: number): string {
		return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
	}
	getWeekLabel(): string {
		return I18N_VALUES[this._i18n.language].weekLabel;
	}
	getMonthShortName(month: number): string {
		return I18N_VALUES[this._i18n.language].months[month - 1];
	}
	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}
	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}/${date.month}/${date.year}`;
	}
}
export const httpLoaderAuth = (tenantService: TenantService): StsConfigLoader => {
  const urlSegments = window.location.pathname.split('/');
  const tenant = urlSegments.length > 1 ? urlSegments[1] : null;
  const urlParams = new URLSearchParams(window.location.search);
  if (window.location.pathname.includes('login')) {
    sessionStorage.removeItem('level');
  } 

  let level = urlParams.get('level');
  if (level) {
    sessionStorage.setItem('level', level); 
  } else {
    level = sessionStorage.getItem('level');
  }
  
 

  return(new StsConfigHttpLoader(from(tenantService.init(tenant, level)).pipe(
    map(info => {
      return {
          authority: `https://cognito-idp.${info.cognitoRegion}.amazonaws.com/${info.cognitoPoolId}`,
          redirectUrl: `${window.location.origin}/${tenantService.tenantName}/home`, 
          clientId: info.cognitoClientId,
          postLoginRoute: `/${tenantService.tenantName}/home`,
          scope: 'email openid phone',
          responseType: 'code',
          silentRenew: true,
          useRefreshToken: true,

        }
    })
  ))
  )
};

@NgModule({
  declarations: [
    AppComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PagesModule,
    TranslateModule.forRoot({
      isolate: false,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LayoutsModule,
    AppRoutingModule,
    SlickCarouselModule,
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderAuth,
        deps: [TenantService],
      },
    }),
   
   
  ],
 
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (languageService: LanguageService,authService: AuthService) => async () => {       
        languageService.init();
        await authService.init();
      },
      deps: [LanguageService, AuthService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
    CurrencyPipe,
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    I18n, 
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
