import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantService } from '@shared/services/tenant.service';

@Component({
  selector: 'multitenant-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})

/**
 * Page 404 component
 */
export class Page404Component implements OnInit {
  next = '';

  private tenantService = inject(TenantService);
  private router = inject(Router);
  private route = inject(ActivatedRoute)
  

  ngOnInit(): void {
    this.next = this.route.snapshot.queryParamMap.get('next');
  }

  goBack(){
    this.router.navigate([`/${this.tenantService.tenantName}/home`]);
  }
}
