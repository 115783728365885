import { Component, inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-log-sales',
  templateUrl: './modal-log-sales.component.html',
  styleUrls: ['./modal-log-sales.component.scss']
})
export class ModalLogSalesComponent implements OnInit {
  @Input() logDetails!: { path?: string; request?: string; response?: string; createdOn?: string; httpStatus?: number };


  public activeModal = inject(NgbActiveModal);



  ngOnInit(): void { }



}
