import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { AuthService } from "@shared/services/auth.service";
import { LayoutService } from "../../layouts/services/layout.service";
import { MenuService } from "@shared/services/menu.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private layoutService: LayoutService,
    private menuService: MenuService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const tenant = route.paramMap.get('tenant');
    const canAccess = this.canAccess(tenant, state.url, route);

    if (canAccess) {
      this.menuService.getUserMenu(tenant).subscribe({
        next: (menu) => {
          this.layoutService.setMenu(menu);
        },
        error: (error) => {
          console.error('Error loading menu:', error);
        }
      });

    }

    return canAccess;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree {
    const urlTree = this.router.getCurrentNavigation().extractedUrl;
    return this.canAccess(segments[0]?.path, this.router.serializeUrl(urlTree), route);
  }

  private canAccess(client: string | undefined, next: string, route: Route | ActivatedRouteSnapshot): boolean | UrlTree {
    const roles = route.data?.roles as Array<string>;

    if (!roles?.length) return true;

    const canAccess = roles.indexOf(this.authService.currentUser?.level) >= 0;

    if (!canAccess) {
      return this.router.parseUrl(`${client}/home`);
    }

    return canAccess;
  }
}
