<multitenant-base-modal>
    <div
      class="modal-title d-flex align-items-center mb-2 justify-content-center">
      <i class="fas fa-money-check-alt text-success small-icon"></i>
      <h4 class="ms-2 mb-0 custom-button">Detalle de la Recarga</h4>
    </div>
  
    <div class="body-container">
      <div class="tab-content" id="nav-tabContent"
        style="position: relative;top: 10px;">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel"
          aria-labelledby="nav-home-tab">
          <div class="card colors-cards">
            <p class="tab-text-commerm">Datos del Usuario</p>
            <div class="card-body">
              <div class="btn-container">
                <label class="text-commerm">Usuario:</label>
                <label
                  class="text-rest">{{rechargeDetails?.fullName}}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Alias:</label>
                <label class="text-ruc">{{rechargeDetails?.alias}}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Operación:</label>
                <label class="text-rest">{{ translateOperation(rechargeDetails?.operation) }}</label>

              </div>
              <div class="btn-container">
                <label class="text-commerm">Código del comercio:</label>
                <label
                  class="text-email">{{rechargeDetails?.merchantId}}</label>
              </div>
            </div>
          </div>
          <div class="card colors-cards">
            <p class="tab-text-commerm">Datos de la transacción</p>
            <div class="card-body">
              <div class="btn-container">
                <label class="text-commerm">Id de la Transacción:</label>
                <label class="text-saleId">{{rechargeDetails?.transactionId}}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Canal:</label>
                <label
                  class="text-channel">{{rechargeDetails?.channel}}</label>
              </div>
             <div class="btn-container">
                <label class="text-commerm">Número de Tarjeta:</label>
                <label
                  class="text-acquirer">{{rechargeDetails?.cardNumber}}</label>
              </div>
               
              <div class="btn-container">
                <label class="text-commerm">Fecha y Hora:</label>
                <label class="text-transactionDate">{{
                  rechargeDetails?.transactionDate | date:
                  'dd/MM/yyyy HH:mm:ss' }}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm">Monto:</label>
                <label class="text-orderAmount">
                  {{ getCurrencySymbol(rechargeDetails?.currency) }} {{ formatAmount(rechargeDetails?.amount, rechargeDetails?.currency) }}
                </label>
              </div>
             
              <div class="btn-container">
                <label class="text-commerm">Estado:</label>
                <span [ngClass]="[getStatusClass(rechargeDetails?.status),'no-background']">
                  <label class="text-center-status">{{getStatusInSpanish(rechargeDetails?.status)}}</label> </span>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="modal-footer justify-content-center">
      @if (isAcquiringUser && rechargeDetails?.status !== 'REVERSED' && rechargeDetails?.status !== 'DENIED') {
        <div class="btn-container">
          <button type="button" class="btn btn-warning" (click)="onAccept()">Anular</button>
        </div>
        }

      <button type="button" class="btn btn-outline-secondary"
        (click)="activeModal.close('Close click')">Cerrar</button>
     
       
    </div>
  
  </multitenant-base-modal>
