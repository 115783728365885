import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Page404Component } from './page404/page404.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [Page404Component],
    imports: [
        CommonModule,
        TranslateModule,
    ],
    exports: [Page404Component],
    providers: []
})
export class PagesModule { }