import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CatalogService } from '@shared/services/catalog.service';
import { RechargeDetails } from '@shared/models/recharge.models';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class RechargeServices {

    private http = inject(HttpClient);
    private catalog = inject(CatalogService);

    detailsRecharge(transactionId: string): Observable<RechargeDetails | undefined> {
        const url = `${this.catalog.serverApi}/cmstransaction/transactionId/${transactionId}`;
        return this.http.get<RechargeDetails>(url).pipe(
            catchError(error => {
                Swal.fire({
                    position: 'top',
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al obtener los detalles de la recarga',
                    showConfirmButton: false,
                    timer: 3500
                });
                return throwError(() => error);
            })
        );
    }
}