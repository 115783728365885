import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[multitenantTrimSpaces]'
})
export class TrimSpacesDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    // Reemplazar dos o más espacios consecutivos con un solo espacio.

     // Obtener el tipo de input (para diferenciar email de otros campos)
     const inputType = inputElement.type;

     let formattedValue = inputElement.value.replace(/\s{2,}/g, ' ').trimStart(); // Elimina espacios al inicio

     if (inputType === 'email') {
      formattedValue = formattedValue.replace(/[^a-zA-Z0-9_@.-]/g, ''); // Solo permite letras, números, _, @ y .
    }

    if (inputElement.value !== formattedValue) {
      setTimeout(() => {
        inputElement.value = formattedValue;
        inputElement.dispatchEvent(new Event('input')); // Disparar evento para actualizar Angular
      });
    }
 
  }
}