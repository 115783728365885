import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CatalogService } from '@shared/services/catalog.service';
import { DetailsTransaction } from '@shared/models/modal-transaction.models';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class TransactionServices {

    private http = inject(HttpClient);
    private catalog = inject(CatalogService);

    detailsTrasactionApi(merchantId: string, saleId: string): Observable<DetailsTransaction | undefined> {
        const url = `${this.catalog.serverApi}/sale/${merchantId}/${saleId}`;
        return this.http.get<DetailsTransaction>(url).pipe(
            catchError(error => {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: 'Error',
                text: 'Error al obtener los detalles de la transacción',
                showConfirmButton: false,
                timer: 3500
            });
              return throwError(() => error);
            })
        );
    }

    getTableTransactions(merchantId: string) {
        const url = `${this.catalog.serverApi}/sale/list/${merchantId}`;
         // Cuerpo del POST
        const body = {
          limit: 10,
          page: 1,
          criteria: [],
          orderBy: "createdOn"
        };
      
        return this.http.post<DetailsTransaction[]>(url, body).pipe(
          catchError(error => {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: 'Error',
              text: 'Error al obtener la lista de la transacción',
              showConfirmButton: false,
              timer: 3500
          });
           return throwError(() => error);
          })
        );
      }
      


}