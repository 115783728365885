<!-- ========== Left Sidebar Start ========== -->
<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class=" list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}}  -->
      @for (item of menuItems; track $index) {
      <ng-container>
        @if(item.isTitle){
        <li class="menu-title">{{ item.label | translate }}</li>
        }@else if(!item.isTitle && !item.isLayout){
        <li>
          @if(hasItems(item)){
          <a href="javascript:void(0);" class="is-parent" [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
            @if(item.icon){
            <i class="bx {{item.icon}}"></i>
            }
            <span> {{ item.label | translate }}</span>
            @if(item.badge){
            <span class="badge rounded-pill float-end bg-{{item.badge.variant}}">{{item.badge.text |
              translate}}</span>
            }
          </a>}
          @else{
          <a [routerLink]="isExternalUrl(item.link) ? null : item.link" 
             [href]="isExternalUrl(item.link) ? 'javascript:void(0)' : null"
             class="side-nav-link-ref" 
             routerLinkActive="active"
             (click)="handleMenuItemClick(item.link)">
            @if(item.icon){
            <i class="bx {{ item.icon }}"></i>
            }
            <!-- name sidebar -->
            <span> {{ item.label | translate }}</span>
            @if(item.badge){
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end">{{item.badge.text |
              translate}}</span>
            }
          </a>
          }
          @if(hasItems(item)){
          <ul class="sub-menu" aria-expanded="false">
            @for (subitem of item.subItems; track $index) {
            <li>
              @if(hasItems(subitem)){
              <a class="side-nav-link-a-ref has-arrow" href="javascript:void(0);" [attr.data-parent]="subitem.parentId">
                {{ subitem.label | translate}}
              </a>
              <ul class="sub-menu mm-collapse" aria-expanded="false">
                @for (subSubitem of subitem.subItems; track $index) {
                <li>
                  <a [attr.data-parent]="subSubitem.parentId" 
                     [routerLink]="isExternalUrl(subSubitem.link) ? null : subSubitem.link"
                     [href]="isExternalUrl(subSubitem.link) ? 'javascript:void(0)' : null"
                     routerLinkActive="active" 
                     class="side-nav-link-ref"
                     (click)="handleMenuItemClick(subSubitem.link)">
                    {{ subSubitem.label | translate }}
                  </a>
                </li>
                }
              </ul>}
              @else{
              <a [routerLink]="isExternalUrl(subitem.link) ? null : subitem.link"
                 [href]="isExternalUrl(subitem.link) ? 'javascript:void(0)' : null"
                 class="side-nav-link-ref" 
                 [attr.data-parent]="subitem.parentId" 
                 routerLinkActive="active"
                 (click)="handleMenuItemClick(subitem.link)">
                {{ subitem.label | translate}}
                @if(subitem.badge){
                <span class="badge rounded-pill bg-{{subitem.badge.variant}} float-end">{{subitem.badge.text |
                  translate}}</span>
                }
              </a>
              }
            </li>
            }
          </ul>
          }
        </li>
        }
      </ng-container>
      }
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->
<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    @if(isCondensed){
    <div>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    }@else {
    <div>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    }
  </ngx-simplebar>
</div>
