import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'multitenant-base-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class BaseModalComponent {

  @Input() data: {
    title?: string;
    body?: string;
    cancelLabel?: string | boolean;
    submitLabel?: string | boolean;
  };
  @Input() loading = false;
 
  constructor(public activeModal: NgbActiveModal) { }

  onClose(result: boolean) {
    this.activeModal.close(result);
  }


 


}


