import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UserService } from './user.service';
import { CatalogService } from './catalog.service';
import { JwtService } from './jwt.service';
import { LoginResult, UserCredentials } from '@shared/models/auth.models';
import { User } from '@shared/models/user.models';
import { CognitoIdentityProviderClient, ConfirmForgotPasswordCommand, ConfirmForgotPasswordCommandInput, ConfirmForgotPasswordCommandOutput, 
  ForgotPasswordCommand, ForgotPasswordCommandInput, InitiateAuthCommand, InitiateAuthCommandInput, InitiateAuthCommandOutput, RespondToAuthChallengeCommand, RespondToAuthChallengeCommandInput, 
  RespondToAuthChallengeCommandOutput } from "@aws-sdk/client-cognito-identity-provider";
import { TenantService } from './tenant.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  get currentUser(): User {
    return this.userService.currentUser;
  }

  get currentUser$(): Observable<User> {
    return this.userService.currentUser$;
  }

  get isAuthenticated$(): Observable<boolean> {
    // Check token availability
    return of(!!this.jwtService.getAccessToken());
  }

  get serverUrl(): string {
    return this.catalog.serverApi;
  }

  get logoutRedirect(): string[] {
    return ['/', this.catalog.client, 'login'];
  }

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private router: Router,
    private userService: UserService,
    private catalog: CatalogService,
    private tenantService: TenantService
  ) {}

  login(loginData: UserCredentials): Observable<{status:string, session?:string, username?: string, attributes?:{}}> {
    let params: InitiateAuthCommandInput = {
      ClientId: this.tenantService.tenantInformation.cognitoPoolId,
      AuthFlow: "USER_PASSWORD_AUTH",
      AuthParameters: {
        USERNAME: loginData.username,
        PASSWORD: loginData.password,
      },
    };
    const command = new InitiateAuthCommand(params);
    return from(this.tenantService.cognitoClient.send(command)).pipe(
      switchMap((authOutput: InitiateAuthCommandOutput) => {
        if (authOutput.ChallengeName === "NEW_PASSWORD_REQUIRED") {
          return of({
            status: 'NEW_PASSWORD_REQUIRED',
            session: authOutput.Session,
            username: loginData.username,
            attributes: authOutput.ChallengeParameters
          });
        } else {
          // Si no se requiere cambio de contraseña, completar el flujo normal de login
          this.doLoginUser(authOutput);
          return this.userService.getUser().pipe(
            tap((user: User) => {
              this.userService.updateCurrentUser(user);
            }),
            map(() => {
              return { status: 'LOGGED_IN' };
            })
          );
        }
      })
    );
  }

  challangeNewPassword(changePassword, newPassword: string): Observable<{status:string}> {
    let params: RespondToAuthChallengeCommandInput = {
      ClientId: this.tenantService.tenantInformation.cognitoPoolId,
      ChallengeName: "NEW_PASSWORD_REQUIRED",
      ChallengeResponses: {
        USERNAME: changePassword.username,
        NEW_PASSWORD: newPassword
      },
      Session: changePassword.session, // La sesión devuelta en el desafío
    };
    // TODO: Implementar el manejo de los atributos requeridos si se requiere en un futuro
    // const userAttributes = JSON.parse(changePassword.attributes['userAttributes']);
    // const requiredAttributes = JSON.parse(changePassword.attributes['requiredAttributes']);
    // for (const attr of requiredAttributes) {
    //   if(userAttributes[attr]){
    //     params.ChallengeResponses[attr] = userAttributes[attr];
    //   }
    // }
    const command = new RespondToAuthChallengeCommand(params);
    return from(this.tenantService.cognitoClient.send(command)).pipe(
      switchMap((response: RespondToAuthChallengeCommandOutput) => {
        this.doLoginUser(response); 
        return this.userService.getUser().pipe(
          tap((user: User) => {
            this.userService.updateCurrentUser(user);
          }),
          map(() => {
            return { status: 'LOGGED_IN' };
          })
        );
      }),
    );
  }

  recoverPassword(username: string): Observable<boolean> {
    let params: ForgotPasswordCommandInput = {
      ClientId: this.tenantService.tenantInformation.cognitoPoolId,
      Username: username,
    };

    const command = new ForgotPasswordCommand(params);
    return from(this.tenantService.cognitoClient.send(command)).pipe(
      map(() => true)
    );
  }

  newPassword(username: string, verificationCode: string, newPassword: string): Observable<boolean> {
    let params: ConfirmForgotPasswordCommandInput = {
      ClientId: this.tenantService.tenantInformation.cognitoPoolId,
      Username: username,
      ConfirmationCode: verificationCode, // Código de verificación recibido por el usuario
      Password: newPassword, // Nueva contraseña proporcionada por el usuario
    };

    const command = new ConfirmForgotPasswordCommand(params);
    return from(this.tenantService.cognitoClient.send(command)).pipe(
      switchMap(() => {
        return this.login({
          username: username,
          password: newPassword
        })
      }),
      map(() => true)
    );
  }

  logout(redirect = true) {
    this.doLogoutUser();
    if (redirect) {
      this.router.navigate(this.logoutRedirect);
    }
  }

  refreshToken() {
    const refreshToken = this.jwtService.getRefreshToken();
    return this.http.post<any>(`${this.serverUrl}/token/refresh/`, {
      refresh: refreshToken,
    }).pipe(
      tap((result) => {
        this.jwtService.saveAccessToken(result.access);
      })
    );
  }

  isAuthenticated(): boolean {
    return !!this.jwtService.getAccessToken();
  }

  private doLoginUser(authOutput: InitiateAuthCommandOutput) {
    const loginResult: LoginResult = {
      accessToken: authOutput.AuthenticationResult.AccessToken,
      refreshToken: authOutput.AuthenticationResult.RefreshToken,
      idToken: authOutput.AuthenticationResult.IdToken,
    };
    this.jwtService.storeTokens(loginResult);
  }

  private doLogoutUser() {
    this.jwtService.removeTokens();
    this.userService.removeCurrentUser();
  }
}
