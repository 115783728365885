<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a [routerLink]="homeLink" class="logo logo-dark">
          <span class="logo-sm">
            <img src="{{companyInformation?.logoLight}}" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="{{companyInformation?.logoDark}}" alt="" height="17">
          </span>
        </a>

        <a [routerLink]="homeLink" class="logo logo-light">
          <span class="logo-sm">
            <img src="{{companyInformation?.logoLight}}" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="{{companyInformation?.logoLight}}" alt="" height="70">
          </span>
        </a>
      </div>

      <button *ngIf="showToggleMenu" type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <form *ngIf="search" class="app-search d-none d-xl-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'search.search' | translate}}">
          <span class="bx bx-search-alt"></span>
        </div>
      </form>

      <div class="extra-content">
        <ng-content></ng-content>
      </div>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
   

      <!-- <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ms-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
        </div>
      </div> -->

      

      <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div> -->

      <!-- <multitenant-user-notifications-topbar></multitenant-user-notifications-topbar> -->
       <ng-container *ngIf="user.merchants as merchants">
         <div class="dropdown d-inline-block" ngbDropdown *ngIf="merchants.length">
           <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
            {{user.chosenMerchant.name}}
             <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
           </button>
             <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
             <a class="dropdown-item" *ngFor="let merchant of merchants" (click)="changeMerchant(merchant)">
               {{ merchant.name }}
             </a>
           </div>
         </div>
       </ng-container>
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <!-- <img *ngIf="user?.profile?.photo" class="rounded-circle header-profile-user" [src]="user.profile.photo" alt="Header Avatar"> -->
          <img class="rounded-circle header-profile-user" src="assets/images/profile-placeholder.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{user?.name}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item">
            <i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{user.level}}
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'header.logout' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</header>
