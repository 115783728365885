import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ColDef, GridOptions, HeaderValueGetterParams, ICellRendererParams } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { ResponseData } from '@shared/models/api.models';
import { CsvMapping, CsvService } from '@shared/services/csv.service';
import { UserService } from '@shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class DataTableService {
  
  defaultColDef: ColDef = {
    flex: 1,
    resizable: true,
    minWidth: 100,
    headerValueGetter: (params: HeaderValueGetterParams) => {
      const field = params.column.getColDef().field
        .replace(/_/g, ' ')
        // Title case
        .replace(
          /\w\S*/g,
          (txt: string) =>  txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
        );
      return params.colDef.headerName ?? field;
    }
  };

  gridOptions: GridOptions = {
    cacheBlockSize: 10,
    cacheOverflowSize: 2,
    columnTypes: {
      loaderColumn: {
        cellRenderer: (params: ICellRendererParams) => {
          const loader = `
          <div style="margin-top: calc(50% - 15px); width: 0;">
          <div class="spinner-chase" style="width: 15px; height: 15px;">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          </div>
          </div>
          `;
          return params.value ?? loader;
        },
      },
      nonEditableColumn: { editable: false },
      dateColumn: {
        filter: 'agDateColumnFilter',
        suppressMenu: true
      },
      booleanColumn: {
        cellRenderer: (params: ICellRendererParams) => {
          if (params.value !== undefined && params.value !== null) {
            return `<i class="bx bx-${params.value ? 'check' : 'x'}-circle text-${params.value ? 'success' : 'danger'}"></i>`
          }
          return params.value;
        }
      }
    },
    infiniteInitialRowCount: 1000,
    maxConcurrentDatasourceRequests: 1,
    maxBlocksInCache: 10,
    pagination: true,
    paginationPageSize: 10,
    rowBuffer: 0,
    rowModelType: 'infinite',
    rowSelection: 'single',
  };

  constructor(
    private http: HttpClient,
    private csvService: CsvService,
    private translate: TranslateService,
    private userService: UserService
  ) { }

  getData(endpoint: string, params: {[key: string]: any},  orderBy: string = ''): Observable<any> {
    const user = this.userService.currentUser;
    if(user.isMerchantUser){
      endpoint = `${endpoint}/${user.chosenMerchant.merchantId}`;
    }
    return this.http.post<any[]>(endpoint,{...params, orderBy});
  }

  getImportModel(endpoint: string): Observable<{[key: string]: boolean}> {
    return this.http.get<ResponseData<{[key: string]: boolean}>>(endpoint).pipe(map(response => response.data));
  }

  import(endpoint: string, body: any): Observable<any> {
    return this.http.post<any[]>(endpoint, body);
  }

  downloadTemplate(data: any, done: (event: MouseEvent) => void, filename?: string) {
    const fields = Object.keys(data);
    filename = filename ?? this.translate.instant('generic.template');
    this.csvService.downloadCsv([], filename, done, fields);
  }

  processImport(file: File, mappings: CsvMapping[], endpoint: string): Promise<{response: any; parsingErrors?: any;}> {
    return new Promise((resolve, reject) => {
      this.csvService.processCsv(file, mappings, (data, parsingErrors) => {
        this.import(endpoint, data).pipe(take(1))
        .pipe(catchError(response => {
          resolve({
            response: response.error,
          });
          return of(null);
        })).subscribe(response => {
          resolve({
            response,
            parsingErrors
          });
        });
      });
    });
  }
}
