import { Injectable } from '@angular/core';
import { Observable, map, of, take } from 'rxjs';
import { TenantInformation, DEFAULT_LOGO_DARK, DEFAULT_LOGO_LIGHT } from '@shared/models/tenant.models';
import { HttpClient, HttpContext } from '@angular/common/http';
import { CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider';
import { BYPASS_LOG } from '@shared/interceptors/http-token.interceptor';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  public cognitoClient: CognitoIdentityProviderClient;
  currentClient: string;
  tenantInformation: TenantInformation;
  themeLoaded = false;
  
  server = '';
  tenantName = '';
  domain = '';

  get api(): string {
    return `${environment.backendApi}/${this.tenantName}`;
  }

  get domainUrl(): string {
    return `${environment.domainServer}/${this.tenantName}`;
  }

  constructor(private http: HttpClient) {}

  async init(client: string, level?: string | null): Promise<TenantInformation> {
    this.tenantName = client;
    this.tenantInformation = await this.getCompanyInformation(level).toPromise();
    this.cognitoClient = new CognitoIdentityProviderClient({ region: this.tenantInformation.cognitoRegion });
    this.setStyleTheme();
    return this.tenantInformation;
  }

  private getCompanyInformation(level?: string | null): Observable<TenantInformation> {
    const options = { context: new HttpContext().set(BYPASS_LOG, true) };
    const url = level ? `${this.api}/tenant/config/?level=${level}` : `${this.api}/tenant/config/`;
    return this.http.get<TenantInformation>(url, options).pipe(
      map((response) => {
        this.tenantInformation = this.processTenantInformation(response);
        return this.tenantInformation;
      })
    );
  }

  private processTenantInformation(companyInformation: any): TenantInformation {
    companyInformation.logoDark = companyInformation.logoDark || DEFAULT_LOGO_DARK;
    companyInformation.logoLight = companyInformation.logoLight || DEFAULT_LOGO_LIGHT;

    if (companyInformation['cognito.client.id']) {
      companyInformation.cognitoClientId = companyInformation['cognito.client.id'];
      delete companyInformation['cognito.client.id'];
    }
    if (companyInformation['cognito.region']) {
      companyInformation.cognitoRegion = companyInformation['cognito.region'];
      delete companyInformation['cognito.region'];
    }
    if (companyInformation['cognito.pool.id']) {
      companyInformation.cognitoPoolId = companyInformation['cognito.pool.id'];
      delete companyInformation['cognito.pool.id'];
    }
    
    return companyInformation;
  }

  private setStyleTheme(): void {
    const head = document.getElementsByTagName('head')[0];

    const style = document.createElement('link');
    style.type = 'text/css';
    style.rel = 'stylesheet';

    if (this.api.includes('supefina')) {
      style.href = 'assets/theme/supefina.css';
    } else if (this.api.includes('bbva')) {
      style.href = 'assets/theme/bbva.css';
    } else if (this.api.includes('ueno')) {
      style.href = 'assets/theme/ueno.css';
    }

    style.onload = () => {
      this.themeLoaded = true;
    };

    head.appendChild(style);
  }
}
