import { Injectable } from "@angular/core";
import { LoginResult } from "@shared/models/auth.models";


@Injectable({
  providedIn: "root",
})
export class JwtService {
  private readonly ACCESS_TOKEN = "ACESS_TOKEN";
  private readonly REFRESH_TOKEN = "REFRESH_TOKEN";
  private readonly ID_TOKEN = "ID_TOKEN";

  constructor() {}

  getIDToken(): string {
    return localStorage[this.ID_TOKEN];
  }

  getAccessToken(): string {
    return localStorage[this.ACCESS_TOKEN];
  }

  saveAccessToken(accessToken : string) {
    localStorage[this.ACCESS_TOKEN] = accessToken;
  }

  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  storeTokens(tokens: LoginResult) {
    localStorage[this.ID_TOKEN] = tokens.idToken;
    localStorage[this.ACCESS_TOKEN] = tokens.accessToken;
    localStorage[this.REFRESH_TOKEN] = tokens.refreshToken;
  }

  removeTokens() {
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.ID_TOKEN);
  }
}
