import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { CatalogService } from "@shared/services/catalog.service";
import { MerchantDetails, Parameters, SubProductDetails } from "@shared/models/merchants.models";
import { UserService } from "@shared/services/user.service";
import { CreateMerchantResponse, MerchantCbo, Status } from "../models/new-merchant.models";
import { GroupDetails, ProductDetails } from "@shared/models/merchants.models";
import {AlertsService} from "@shared/services/alerts.service";

@Injectable({
  providedIn: "root",
})
export class MerchantsServices {

  private http = inject(HttpClient);
  private catalog = inject(CatalogService);
  private userService = inject(UserService);
  private alert = inject(AlertsService);

  newMerchant(data: CreateMerchantResponse): Observable<CreateMerchantResponse> {
    const user = this.userService.currentUser;
    let endpoint = `${this.catalog.serverApi}/merchant`;
    if (user.isMerchantUser) {
      endpoint = `${endpoint}/${user.chosenMerchant.merchantId}`;
    }
    return this.http.post<CreateMerchantResponse>(endpoint, data).pipe(
      catchError((error) => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      })
    );
  }

  editMerchant(data: CreateMerchantResponse): Observable<CreateMerchantResponse> {
    const user = this.userService.currentUser;
    let endpoint = `${this.catalog.serverApi}/merchant`;
    if (user.isMerchantUser) {
      endpoint = `${endpoint}/${user.chosenMerchant.merchantId}`;
    }
    return this.http.patch<CreateMerchantResponse>(endpoint, data).pipe(
      catchError((error) => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      })
    );
  }

  getGroups(): Observable<GroupDetails[] | undefined> {
    const url = `${this.catalog.serverApi}/group/cbo?query=`;
    return this.http.get<GroupDetails[]>(url).pipe(
      catchError((error) => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      })
    );
  }

  getProduct(): Observable<ProductDetails[] | undefined> {
    const url = `${this.catalog.serverApi}/product/cbo?query=`;
    return this.http.get<ProductDetails[]>(url).pipe(
      catchError((error) => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      }),
    );
  }

  getSubProduct(productId: string): Observable<SubProductDetails[] | undefined> {
    const url = `${this.catalog.serverApi}/subproduct/${productId}/cbo?query=`;
    return this.http.get<SubProductDetails[]>(url).pipe(
      catchError((error) => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      }),
    );
  }

  getParameters(productId: string, subProductId: string): Observable<Parameters[] | undefined> {
    const url = `${this.catalog.serverApi}/subproduct/${productId}/${subProductId}`;
    return this.http.get<Parameters[]>(url).pipe(
      catchError((error) => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      })
    );
  }

  idmerchant(merchantId: string): Observable<CreateMerchantResponse | undefined> {
    const url = `${this.catalog.serverApi}/merchant/${merchantId}`;
    return this.http.get<CreateMerchantResponse>(url).pipe(
      catchError((error) => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      })
    );
  }

  detailsMerchantApi(merchantId: string): Observable<MerchantDetails | undefined> {
    const url = `${this.catalog.serverApi}/merchant/${merchantId}`;
    return this.http.get<MerchantDetails>(url).pipe(
      catchError((error) => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      })
    );
  }

  updateStatus(merchantId: string, active: boolean): Observable<Status> {
    const user = this.userService.currentUser;
    let endpoint = `${this.catalog.serverApi}/merchant/status`;
    if (user.isMerchantUser) {
      endpoint = `${endpoint}/${user.chosenMerchant.merchantId}`;
    }
    return this.http.patch<Status>(endpoint, { merchantId, active }).pipe(
      catchError((error) => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      })
    );
  }

  getCboMerchant(): Observable<MerchantCbo[] | undefined> {
    const url = `${this.catalog.serverApi}/merchant/cbo?query=`;
    return this.http.get<MerchantCbo[]>(url).pipe(
      catchError((error) => {
        this.alert.handleHttpError(error);
        return throwError(() => new Error(error));
      })
    );
  }
}