import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@shared/models/user.models';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-modal-detail-user',
  templateUrl: './modal-detail-user.component.html',
  styleUrl: './modal-detail-user.component.scss'
})
export class ModalDetailUserComponent {
  public activeModal = inject(NgbActiveModal);
  @Input() userId!: string;
  private userService = inject(UserService);
  userdetails;

  ngOnInit(): void {
    this.getMerchanDetails();
  }

  getMerchanDetails(): void {
    this.userService.getUserById(this.userId).subscribe((response) => {
      this.userdetails = response
     });
  }
  getStatusClass(status: string) {
    switch (status) {
      case 'ACTIVE':
        return 'badge-soft-success';
      case 'INACTIVE':
        return 'badge-soft-danger';
      default:
        return 'badge-soft-primary';
    }
  }

  getRoleTranslate(role: string): string {
    const roles = [
      { name: 'Adquirente Administrador', value: 'acquirer-admin' },
      { name: 'Adquirente Operador', value: 'acquirer-operator' },
      { name: 'Comercio Administrador', value: 'merchant-admin' },
      { name: 'Comercio Operador', value: 'merchant-operator' },
    ];

    const roleObj = roles.find(r => r.value === role);
    return roleObj ? roleObj.name : role;
  }

  getStatusTranslate(status: string): string {
    const statuses = [
      { name: 'Activo', value: 'ACTIVE' },
      { name: 'Inactivo', value: 'INACTIVE' },
    ];

    const statusObj = statuses.find(s => s.value === status);
    return statusObj ? statusObj.name : status;
  }
}
