<div class="right-bar" (clickOutside)="hide()" [clickOutsideExclude]="'.right-bar-toggle'">
  <ngx-simplebar class="h-100">
    <div class="rightbar-title px-3 py-4" *ngIf="rightView$ | async as rightView">
      <ng-container [ngSwitch]="rightView.view">
        <ng-container *ngSwitchCase="'filters'">
          <multitenant-filters [filterCategories]="rightView.filters"></multitenant-filters>
        </ng-container>
        <ng-container *ngSwitchCase="'columns'">
            <multitenat-columns [items]="rightView.columns"></multitenat-columns>
        </ng-container>
      </ng-container>
    </div>
  </ngx-simplebar>
</div>
<div class="rightbar-overlay"></div>